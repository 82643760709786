import React, { useState } from 'react';
import DaySlotHolder from './DaySlotHolder';
import WeekSelector from './WeekSelector';
import FilterComponent from './FilterComponent';
import './Booking.css'

export function BookingContainer() {
    const mockAppointmentTypes = ["Type 1", "Type 2"];

    const mockProviders = [
        {
            provider_ID: "jsi3jdkfjlsiejd",
            provider_Nickname: "Provider A",
            provider_role_and_organization: "Role A"
        },
        {
            provider_ID: "alkj3i4ojlkjadf",
            provider_Nickname: "Provider B",
            provider_role_and_organization: "Role B"
        }
    ];
    const aptType1 = {
        Label: "Requested",
        ID: "asdfi4jdkfj",
        LocId: "jhsdfih",
        LocationName: "KMBL 9",
        StartTime: "15:00",
        EndTime: "15:20",
        Duration: "20",
        TimeUnit: 'minutes',
        TimeZone: "MST",
        requiresLogin: true,
        requiresMembership: false,
        allowPublicJoining: true
    }
    const providerA = {
        provider_ID: "jsi3jdkfjlsiejd",
        provider_Nickname: "Provider A",
        provider_role_and_organization: "Role A",
        
    }
    const providerB = {
        provider_ID: "alkj3i4ojlkjadf",
        provider_Nickname: "Provider B",
        provider_role_and_organization: "Role B"
    }

    const weekAppointments = {
        Sunday: [{ appointment: aptType1, provider: providerA }, { appointment: aptType1, provider: providerA },{ appointment: aptType1, provider: providerA }],
        Monday: [
            { appointment: aptType1, provider: providerA }
        ],
        Tuesday: [{ appointment: aptType1, provider: providerA }],
        Wednesday: [{ appointment: aptType1, provider: providerA }],
        Thursday: [{appointment: aptType1, provider: providerB}],
        Friday: [{ appointment: aptType1, provider: providerA }],
        Saturday: [{ appointment: aptType1, provider: providerA },{ appointment: aptType1, provider: providerA },{ appointment: aptType1, provider: providerA },{ appointment: aptType1, provider: providerA },{ appointment: aptType1, provider: providerA },{ appointment: aptType1, provider: providerA },{ appointment: aptType1, provider: providerA },{ appointment: aptType1, provider: providerA },{ appointment: aptType1, provider: providerA }]
    };


    function getCurrentWeekStartDate() {
        const date = new Date();
        const day = date.getDay();
        const diff = date.getDate() - day; // This ensures Sunday is the start
        return new Date(date.setDate(diff));
    }


    function changeWeek(amount) {
        let newDate = new Date(currentWeekStartDate);
        newDate.setDate(newDate.getDate() + (7 * amount));
        setCurrentWeekStartDate(newDate);
    }

    function handleAppointmentTypeChange(type) {
        setSelectedAppointmentType(type);
        // Add API call or other logic here if needed
    }

    function handleProviderChange(provider) {
        setSelectedProvider(provider);
        // Add API call or other logic here if needed
    }

    const [selectedAppointmentType, setSelectedAppointmentType] = useState(null);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [currentWeekStartDate, setCurrentWeekStartDate] = useState(getCurrentWeekStartDate());

    const [providers, setProviders] = useState([]);

    return (
        <div style={containerStyle} className="booking-container">
            <div className='filter-component'>
                <FilterComponent
                    appointmentTypes={mockAppointmentTypes}
                    providers={mockProviders}
                    onTypeChange={handleAppointmentTypeChange}
                    onProviderChange={handleProviderChange}
                />
            </div>
            <div style={weekSelectorStyle}>
                <WeekSelector
                    dateRange={`${currentWeekStartDate.toDateString()} - ${new Date(currentWeekStartDate.getTime() + 6 * 24 * 60 * 60 * 1000).toDateString()}`}
                    onPrevClick={() => changeWeek(-1)}
                    onNextClick={() => changeWeek(1)}
                />
            </div>
            <div style={daySlotHolderStyle} className="day-slots">
                {Object.keys(weekAppointments)
                    .filter(day => weekAppointments[day].length > 0)
                    .map(day => (
                        <DaySlotHolder
                            key={day}
                            day={day}
                            appointments={weekAppointments[day]}
                        />
                    ))}
            </div>
        </div>
    );
}
//Styles:
const containerStyle = {
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    width: '100%',
    margin: '50px auto'
};



const weekSelectorStyle = {
    marginBottom: '20px',
    textAlign: 'center',
    fontWeight: 'bold'
};

const daySlotHolderStyle = {
    display: 'flex',
    justifyContent: 'space-evenly'
    
};



const appointmentHolderStyle = {
    margin: '10px 0',
    background: '#ffffff',
    padding: '8px',
    borderRadius: '6px'
};
