import React, { useEffect, useState, useContext, useMemo } from 'react';
import { useParams, NavLink } from 'react-router-dom'
import { Megaphone, GearFill } from 'react-bootstrap-icons';

import { Container, Row, Col, Card, ButtonGroup, Button } from 'react-bootstrap';
import './Dashboard.css'
import OrganizationAnnouncements from './OrganizationAnnouncements';
import Feed from './Feed';
import UpcomingEvents from './UpcomingEvents';
import MyAppointments from './MyAppointments';
import MyAssignments from './MyAssignments';
import { Spinner } from '../../CustomComponents/Spinners/Spinner';

const GroupDashboard = (props) => {
  const { orgID } = useParams();
  const [OrgName, setOrgName] = useState('Group Name');
  const [OrgSubStatus, setSubStatus] = useState(null);
  const [CanSendPushToOrganizations, setPushPermission] = useState(false);
  const [displayLoader, setLoader] = useState(false);
  const [ApprovalStatus, setApprovalStatus] = useState(false);
  const [Message, setMessage] = useState('Loading...');
  const [Action, setAction] = useState(null);
  //Get the user's enrollment, info, and permissions list for the dashboard to render properly based on roles.
  //Get enrollment status to then prevent other components from loading until the user is fully enrolled.
  //If the user is not approved yet, disable all buttons except leave group, re-label button to cancel join request, and hide the other buttons in the group.
  useEffect(() => {
      //Get Group details:
      fetch(`/v1/orgs/${orgID}/details`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
        .then(response => {
          if (!response.ok) {
            if(response.status === 404){
              alert('Group was not found, check that you are enrolled in the group.');
              window.location.href='/groups';
            }
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          // Do something with the data
          console.log(data);
          setOrgName(data.OrgName);
          setSubStatus(data.OrgSubStatus);
        })
        .catch(error => {
          // Handle any errors that occurred during the request
          console.error('There was a problem with the fetch operation:', error);
        });


    
  }, []);
  useEffect(() => {
    
      //Get user permissions for this group
      fetch(`/api/groups/${orgID}/permissions`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          // Do something with the data
          //console.log(data);
          //setPermissions(data);
          setPushPermission(data.permissions.CanSendPushToOrganizations);
          console.log(CanSendPushToOrganizations);
        })
        .catch(error => {
          // Handle any errors that occurred during the request
          console.error('There was a problem with the fetch operation:', error);
        });
    
  }, []);

  useEffect(() => {
    
      //Get user enrollment for this group
      fetch(`/api/groups/${orgID}/enrollment`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
        .then(response => {
          if (response.status === 202) {
            response.json().then((responseBody) => {
              const message = responseBody.msg;
              console.warn(message);
              setMessage(message);
              setLoader(true);
              setApprovalStatus(false);
              function BackBtn() {
                return (
                  <button type="button" class="btn btn-secondary" onClick={() => window.location.href = `/groups`}>Back to Groups</button>
                )
              };
              setAction(BackBtn);
            })
          }
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          // Do something with the data
          console.log(data);
        })
        .catch(error => {
          // Handle any errors that occurred during the request
          console.error('There was a problem with the fetch operation:', error);
        });
    
  }, [ApprovalStatus]);

  
  function handleApproval(event) {
    if (event.success === true) {
      setMessage('Approval Recieved!');
      setApprovalStatus(true);
      setAction(null);
      //alert('Approval Recieved!');
      setLoader(false);

    }
  }
  
  return (
    <Container fluid>
      {displayLoader && <Spinner action={Action} message={Message} />}

      <Row className="mb-4">
        <Col>
          <h2 className="text-center">{OrgName}</h2>
          <p className='text-center'> <small>({OrgSubStatus})</small></p>
        </Col>
      </Row>
      
      <Row>

        <Col xs={12} md={6} lg={4}>

          <Card className="mb-4">
            <Card.Header>Organization Announcements</Card.Header>
            <Card.Body>
              <OrganizationAnnouncements orgID={orgID} />
            </Card.Body>
          </Card>
          <Card className="mb-4">
            <Card.Header>My Assignments</Card.Header>
            <Card.Body>
              <MyAssignments />
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={4}>
          <Card className="mb-4">
            <Card.Header>Feed</Card.Header>
            <Card.Body>
              <Feed />
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6} md={{ order: 'first' }} lg={4}>
          <Card className="mb-4">
            <Card.Header>Upcoming Events</Card.Header>
            <Card.Body>
              <UpcomingEvents />
            </Card.Body>
          </Card>
          <Card className="mb-4">
            <Card.Header>My Appointments</Card.Header>
            <Card.Body>
              <MyAppointments />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default GroupDashboard;
