import { Card } from "react-bootstrap";


const UserCard = (props) => {
    const { user, Actions } = props;
    console.log(user)

    
    if(!user){
        return(
        <Card style={{ width: '15rem', margin:'10px'}}>
                {Actions}

        </Card>
        )
    }
    return (
        <Card style={{ width: '15rem', margin:'10px'}}>
            <div className="d-flex justify-content-center" style={{ marginTop: '5%' }}>
            <Card.Img 
                    variant="top" 
                    src={processImageUrl(user.profile_image)} 
                    style={{ width: '90%', borderRadius: '50%', border: '1px solid black' }} 
                    onError={(e) => e.target.src = "/Default_Profile.png"} // Fallback for broken links
                />            </div>           
            {user.alias && (<div className="d-flex justify-content-center">
            <small>@ {user.alias}</small>
            </div>)} 
            <Card.Body>
                <strong>Name: </strong> {user.first_name && (<>{user.first_name}</>)} {user.last_name && (<>{user.last_name}</>)} {user.nickname && (<>({user.nickname})</>)}
                {user.roles && (<div>
                    <strong>Roles: </strong>
                    <div><small>{user.roles.join(", ")}</small></div>
                </div>)}
                <strong></strong>
            </Card.Body>
            <Card.Footer>{Actions}</Card.Footer>
        </Card>
    )
}

export function processImageUrl(imageUrl) {
    if (!imageUrl) {
        return "/Default_Profile.png"; // Default image if no URL is provided
    }
    try {
        // Try to parse if the URL is JSON-escaped, otherwise use as is
        return JSON.parse(imageUrl);
    } catch (error) {
        return imageUrl;
    }
};

export default UserCard;