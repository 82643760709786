import React, { useState } from 'react';
import './Booking.css';
import { Auth } from 'aws-amplify';
import { Authenticator } from "@aws-amplify/ui-react";

import { Modal, Button, Form } from 'react-bootstrap';


export default function AppointmentHolder({ appointment, provider }) {
    const [isNoLoginModalOpen, setIsNoLoginModalOpen] = useState(false);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [isLoginRegisterOpen, setIsLoginRegisterModalOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);


    function handleClickAppt() {
        console.log("Appt Selected:", appointment);
        Auth.currentAuthenticatedUser()
        .then(user => {
            // User is logged in
            console.log('User is logged in:', user);
            setIsLoggedIn(true);
        })
        .catch(err => {
            // User is not logged in or error occurred
            console.log('User is not logged in or error:', err);
            setIsLoggedIn(false);
        });

        // If the appointment doesn't require login, you could potentially just book it directly or
        // handle it in a different way not requiring any modals
        if (!appointment.requiresLogin && !appointment.requiresMembership && appointment.allowPublicJoining) {
            if(isLoggedIn){
                
                //Show register for organization modal
            }
            else{
                setIsNoLoginModalOpen(true);
            }
            return;
        }
        else {
            //Case if you need an account but not be a member (AKA public registration)
            if(appointment.requiresLogin && ! appointment.requiresMembership && appointment.allowPublicJoining){
                //Check if the user is a member within the greater organization, If they are, display the LoginModalOpen, else, display LoginModalRegister
            }
            //Requires a user to be a member AND be logged in
            else if( appointment.requiresLogin && appointment.requiresMembership && !appointment.allowPublicJoining){
                //Check if the user is a member of the greater organization, if they are not, tell them they must request to join the group, if they are, show the LoginModalOpen
                setIsLoginModalOpen(true)


            }
           
            
        }

        // Check if the user is logged in
        
    }


    // Placeholder functions for submitting the form (to be implemented)
    function handleSubmitNoLogin(details, appointment) {
        console.log("Submitting no login details", details, appointment);
        // Add your API call logic here
    }

    function handleSubmitLogin(details, apppointment) {
        console.log("Submitting login details", details);
        // Add your API call logic here
    }
    function handleLoggedInRegistrationAppt(details, appointment){

    }

    return (
        <div>
            <div className="appointment-style-holder" style={appointmentHolderStyle} onClick={handleClickAppt}>
                <strong>{appointment.Label} <small>({appointment.Duration} {appointment.TimeUnit})</small></strong>
                <p>{appointment.StartTime} - {appointment.EndTime} {appointment.TimeZone}</p>
                <p>{provider.provider_Nickname}</p>
                <p><small>{provider.provider_role_and_organization}</small></p>
                <small>{appointment.LocationName}</small>
            </div>

            {isNoLoginModalOpen && (
                <NoLoginModal
                    appointment={appointment}
                    onSubmit={handleSubmitNoLogin}
                    onHide={() => setIsNoLoginModalOpen(false)}
                    show={isNoLoginModalOpen}
                    provider={provider}
                />
            )}

            {isLoginModalOpen && (
                <LoginModal
                    appointment={appointment}
                    onSubmit={handleSubmitLogin}
                    onHide={() => setIsLoginModalOpen(false)}
                    show={isLoginModalOpen}
                    provider={provider}
                />
            )}
            {isLoginRegisterOpen && (
                <LoginModalRegister
                    appointment={appointment}
                    onSubmit={handleLoggedInRegistrationAppt}
                    onHide={()=> setIsLoginRegisterModalOpen(false)}
                    show={isLoginRegisterOpen}
                    provider={provider}
                    />
            )}
        </div>
    );
}

const appointmentHolderStyle = {
    margin: '5px 0',
    background: '#0a2a52',
    color: 'white',
    padding: '2px',
    borderRadius: '6px'
};

// NoLoginModal.js and LoginModal.js will be your modal components where you'll implement the forms and other UI elements.
function NoLoginModal({ show, onHide, onSubmit, appointment, provider }) {
    const [userDetails, setUserDetails] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        subgroup: '' // assuming subgroup is a dropdown
    });

    // Function to handle form field changes
    const handleChange = (e) => {
        setUserDetails({
            ...userDetails,
            [e.target.name]: e.target.value
        });
    };

    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(userDetails, appointment);
        onHide(); // Close the modal after submitting
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Book Appointment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formBasicFirstName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter first name"
                            name="firstName"
                            value={userDetails.firstName}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicLastName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter last name"
                            name="lastName"
                            value={userDetails.lastName}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Enter email"
                            name="email"
                            value={userDetails.email}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicPhone">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                            type="tel"
                            placeholder="Enter phone number"
                            name="phoneNumber"
                            value={userDetails.phoneNumber}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicSubgroup">
                        <Form.Label>Sub-group</Form.Label>
                        <Form.Control
                            as="select"
                            name="subgroup"
                            value={userDetails.subgroup}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select subgroup</option>
                            <option value="subgroup1">Subgroup 1</option>
                            <option value="subgroup2">Subgroup 2</option>
                        </Form.Control>
                    </Form.Group>

                    <div className="appointment-details">
                        <p><strong>Appointment Type:</strong> {appointment.Label}</p>
                        <p><b>Appointment Time:</b> {appointment.StartTime} - {appointment.EndTime} <small>{appointment.TimeZone}</small></p>
                        <p><b>Location:</b> {appointment.LocationName}</p>
                    </div>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button variant="primary" type="submit" onClick={handleSubmit}>
                    Book Appointment
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
//Modal for requires a user to be logged in but not be a member of a group.
function LoginModalRegister({ appointment, onSubmit, onHide, show }) {
    
    const [group, setGroup] = useState({
        groupName:'',
        subgroup: '' // assuming subgroup is a dropdown
    });
    const handleChange = ()=>{}
    // Placeholder for checking permissions, etc.
    const checkPermissionsAndBook = () => {
        // Auth.currentAuthenticatedUser().then(user => {
        // Call an API endpoint with user details and the appointment to book it
        // For now, we'll just call the onSubmit with placeholder data
        onSubmit({
            /* user and appointment details */
        });
        onHide();
        // });
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Book Appointment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="appointment-details">
                    <p><strong>Appointment Type:</strong> {appointment.Label}</p>
                    <p><b>Appointment Time:</b> {appointment.StartTime} - {appointment.EndTime} <small>{appointment.TimeZone}</small></p>
                    <p><b>Location:</b> {appointment.LocationName}</p>
                </div>
            </Modal.Body>
            <Authenticator
                hideSignUp={true}>
                    <Form.Control
                            as="select"
                            name="subgroup"
                            value={group.subgroup}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select subgroup</option>
                            <option value="subgroup1">Subgroup 1</option>
                            <option value="subgroup2">Subgroup 2</option>
                        </Form.Control>
                <Button variant="primary" type="submit" onClick={checkPermissionsAndBook}>
                    Book Appointment
                </Button>
            </Authenticator>
        </Modal>
    );
}
function LoginModal({ appointment, onSubmit, onHide, show }) {
    const [userData, setUserData] = useState({
        // Assuming the user's primary enrollment or other details can be loaded here
    });

    // Placeholder for checking permissions, etc.
    const checkPermissionsAndBook = () => {
        // Auth.currentAuthenticatedUser().then(user => {
        // Call an API endpoint with user details and the appointment to book it
        // For now, we'll just call the onSubmit with placeholder data
        onSubmit({
            /* user and appointment details */
        });
        onHide();
        // });
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Book Appointment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="appointment-details">
                    <p><strong>Appointment Type:</strong> {appointment.Label}</p>
                    <p><b>Appointment Time:</b> {appointment.StartTime} - {appointment.EndTime} <small>{appointment.TimeZone}</small></p>
                    <p><b>Location:</b> {appointment.LocationName}</p>
                </div>
            </Modal.Body>
            {/* Your AWS Amplify login component should be placed here */}
            {/* For example: <AmplifySignIn /> */}
            {/* After the user is signed in, you can show the booking details and a confirmation button */}
            <Authenticator
                hideSignUp={true}>
                <Button variant="primary" type="submit" onClick={checkPermissionsAndBook}>
                    Book Appointment
                </Button>
            </Authenticator>
        </Modal>
    );
}