import { CAccordion, CAccordionBody, CAccordionHeader, CAccordionItem } from "@coreui/react/dist";
import { useState } from "react";
import { Button, Modal, Spinner, Table } from "react-bootstrap";
import { processImageUrl } from "./UserViews/UserCard";
import { ArrowCounterclockwise, Check, InfoCircle, X } from "react-bootstrap-icons";
const RoleRow = (props) => {
    const { role, Action, orgId, permissions } = props;
    const [showModal, setModalDisp] = useState(false);
    const [activeUsers, setActiveUsers] = useState([{}])
    const [proposedUsers, setProposedUsers] = useState([]);
    const [isLoadingApproved, setIsLoading] = useState(false);
    const [isLoadingProposed, setIsLoadingProposed] = useState(false);
    const toggleModal = () => {
        setModalDisp(!showModal);
    }

    const handleViewRoleModal = () => {

    }
    const deactivateUserRole = (e) => {
        alert("Deactivating " + e);
    }
    const declineProposed = (e)=>{

    }
    const apporveProposed = (e)=>{

    }
    const viewUserRoleData = (e)=>{

    }
    return (
        <>
            <Modal onHide={toggleModal} show={showModal}>
                <Modal.Header closeButton><Modal.Title>{role.roleName}</Modal.Title></Modal.Header>
                <Modal.Body>
                    <CAccordion>
                        <CAccordionItem itemKey={1}>
                            <CAccordionHeader>Role Info</CAccordionHeader>
                            <CAccordionBody>
                                <div>Description:</div>{role.roleDescription}
                            </CAccordionBody>
                        </CAccordionItem>
                        <CAccordionItem itemKey={2}>
                            <CAccordionHeader>Users ({activeUsers.length})</CAccordionHeader>
                            <CAccordionBody>
                                <div style={{ overflowX: 'auto', maxHeight: '400px' }}>
                                    <Table striped boardered hover>
                                        <thead>
                                            <tr>
                                                <th />
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoadingApproved &&
                                                (<><tr><td><><Spinner /></></td><td>Loading ...</td></tr></>)}
                                            {activeUsers.map(user => (
                                                <tr key={user.sub_id} onClick={()=>{viewUserRoleData(user.roleId)}}>
                                                <td><img src={processImageUrl(user.profile_image)} style={{ width: '50px', height: '50px' }} /></td>
                                                    <td>{user.first_name}</td>
                                                    <td>{user.last_name}</td>
                                                    <td>
                                                    <Button variant="info" onClick={()=>{viewUserRoleData(user.roleId)}}><InfoCircle/></Button>

                                                        <Button variant="danger" onClick={() => { deactivateUserRole(user.roleId) }}><ArrowCounterclockwise /></Button>
                                                        </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </CAccordionBody>
                        </CAccordionItem>
                        <CAccordionItem itemKey={3}>
                            <CAccordionHeader>Proposed Users ({proposedUsers.length})</CAccordionHeader>
                            <CAccordionBody>
                                <div style={{ overflowX: 'auto', maxHeight: '400px' }}>
                                    <Table striped boardered hover>
                                        <thead>
                                            <tr>
                                                <th />
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoadingProposed &&
                                                (<><tr><td><><Spinner /></></td><td>Loading ...</td></tr></>)}
                                            {activeUsers.map(user => (
                                                <tr key={user.sub_id} onClick={()=>{viewUserRoleData(user.roleId)}}>
                                                    <td><img src={processImageUrl(user.profile_image)} style={{ width: '50px', height: '50px' }} /></td>
                                                    <td>{user.first_name}</td>
                                                    <td>{user.last_name}</td>
                                                    <td>
                                                        <Button variant="success" onClick={()=>{apporveProposed(user.roleId)}}><Check/></Button>
                                                        <Button variant="info" onClick={()=>{viewUserRoleData(user.roleId)}}><InfoCircle/></Button>
                                                        <Button variant="danger" onClick={() => { declineProposed(user.roleId) }}><X/></Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </CAccordionBody>
                        </CAccordionItem>
                        <CAccordionItem itemKey={4}>
                            <CAccordionHeader>Permissions ({role.permissionCount})</CAccordionHeader>
                            <CAccordionBody>

                            </CAccordionBody>
                        </CAccordionItem>
                    </CAccordion>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
            <tr>
                <td onClick={toggleModal}>{role.roleName}</td>
                <td onClick={toggleModal}>{role.roleDescription}</td>
                <td>{Action}</td>
            </tr>
        </>
    )
}
export default RoleRow;