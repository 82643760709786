import React, { useEffect, useState } from 'react';
import { useTable } from 'react-table';
import { AuthGetRequest } from '../APIGateWayRequestMiddleWare';
import { Table, Button } from 'react-bootstrap';
import { Pencil, Plus } from 'react-bootstrap-icons';
import { Auth, Hub } from 'aws-amplify';
import moment from 'moment';
import { Card, Col, Row } from 'react-bootstrap';



const MySubscriptions = () => {
    const [data, setData] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState(null);
    useEffect(() => {
        // Check the initial authentication state when the component mounts
        checkUser();

        // Subscribe to authentication events
        const authSubscription = Hub.listen('auth', ({ payload }) => {
            if (payload.event === 'signIn') {
                // User has signed in
                checkUser();
            } else if (payload.event === 'signOut') {
                // User has signed out
                setUser(null);
            }
        });

        // Clean up the subscription when the component unmounts
        return () => {
            authSubscription();
        };
    }, []);
    async function checkUser() {
        try {
            await Auth.currentAuthenticatedUser();
            setIsLoggedIn(true); // User is authenticated
        } catch (error) {
            setIsLoggedIn(false); // No user is signed in
        }
    }

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await AuthGetRequest('/v1/user/my-subscriptions');
                setData(response.data.userSubscriptions);
            } catch (error) {
                console.error('Error fetching subscriptions:', error);
            }
        }
        if (isLoggedIn) {
            fetchData();
        }

    }, [isLoggedIn]);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Tier Name',
                accessor: 'tierName'
            },
            {
                Header: 'Tier Type',
                accessor: 'tierType'
            },
            {
                Header: 'Start Date',
                accessor: row => {
                    if (!row.subscriptionStartDate) return '';
                    const utcDate = moment.utc(row.subscriptionStartDate, 'MMM D, YYYY, h:mm:ss A');
                    return utcDate.local().format('MMM D, YYYY, h:mm A');
                }
            },
            {
                Header: 'End Date',
                accessor: row => {
                    if (!row.subscriptionEnd) return '';
                    const utcDate = moment.utc(row.subscriptionEnd, 'MMM D, YYYY, h:mm:ss A');
                    return utcDate.local().format('MMM D, YYYY, h:mm A');
                }
            },
            ,
            {
                Header: 'Is Active',
                accessor: row => (row.isActive ? '✅' : '❌'),
                Cell: ({ value }) => (
                    <span style={{ color: value === '✅' ? 'green' : 'red' }}>{value}</span>
                )
            },
            {
                Header: 'Action',
                id: 'isRedeemed',
                accessor: row => {
                    if (row.isActive) {
                        console.log(row.subscriptionEnd);
                        if (row.subscriptionEnd == null) {
                            return row.isRedeemed ? 'edit' : 'add';
                        } else {
                            return 'Expiring Soon';
                        }
                    }
                    return 'Expired License';
                },
                Cell: ({ value, row }) => {
                    if (value === 'edit') {
                        return (
                            <div>
                                <Button variant="primary" onClick={() => editGroup(row.original.subscriptionId)}>
                                    <Pencil /> Edit Group
                                </Button>
                                <Button variant='danger' style={{ marginLeft: '10px' }} onClick={() => deleteGroup(row.original.subscriptionId)}>Cancel License</Button>
                            </div>
                        );
                    } else if (value === 'add') {
                        return (
                            <div>
                                <Button variant="success" onClick={() => createGroupWithLicense(row.original.subscriptionId)}>
                                    <Plus /> Create Group
                                </Button>
                                <Button variant='danger' style={{ marginLeft: '10px' }} onClick={() => deleteGroup(row.original.subscriptionId)}>Cancel License</Button>

                            </div>


                        );
                    } else {
                        return <span>{value}</span>;  // This will display 'hasEnd' or 'notActive'
                    }
                }

            }
        ],
        []
    );

    const {
        headerGroups,
        rows,
        prepareRow
    } = useTable({ columns, data });

    const renderSubscriptionCard = (row) => {
        console.log(row);
        return (
            <Card className="mb-3">
                <Card.Body>
                    <Card.Title>{row.original.tierName}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">{row.original.tierType}</Card.Subtitle>
                    <Card.Text>
                        Start Date: {row.original.subscriptionStartDate ? moment.utc(row.original.subscriptionStartDate, 'MMM D, YYYY, h:mm:ss A').local().format('MMM D, YYYY, h:mm A') : ''}
                        <br />
                        End Date: {row.original.subscriptionEnd ? moment.utc(row.original.subscriptionEnd, 'MMM D, YYYY, h:mm:ss A').local().format('MMM D, YYYY, h:mm A') : ''}
                        <br />
                        Is Active: <span style={{ color: (row.original.isActive ? '✅' : '❌') === '✅' ? 'green' : 'red' }}>{row.original.isActive ? '✅' : '❌'}</span>
                    </Card.Text>
                    {/* Render actions according to the logic */}
                    {row.original.isActive && (
                        row.original.subscriptionEnd == null
                            ? (row.original.isRedeemed
                                ? (
                                    <div>
                                        <Button variant="primary" onClick={() => editGroup(row.original.subscriptionId)}>
                                            <Pencil /> Edit Group
                                        </Button>
                                        <Button variant='danger' style={{ marginLeft: '10px' }} onClick={() => deleteGroup(row.original.subscriptionId)}>Cancel License</Button>
                                    </div>
                                )
                                : (
                                    <div>
                                        <Button variant="success" onClick={() => createGroupWithLicense(row.original.subscriptionId)}>
                                            <Plus /> Create Group
                                        </Button>
                                        <Button variant='danger' style={{ marginLeft: '10px' }} onClick={() => deleteGroup(row.original.subscriptionId)}>Cancel License</Button>
                                    </div>
                                ))
                            : <span>Expiring Soon</span>
                    )}
                    {!row.original.isActive && <span>Expired License</span>}
                </Card.Body>
            </Card>
        );
    }


    const editGroup = (subscriptionId) => {
        console.log('Edit group for subscription ID:', subscriptionId);
        // Call your API or handler here
    };
    const deleteGroup = (subscriptionId) => {

    }

    const createGroupWithLicense = (subscriptionId) => {
        console.log('Create group with license for subscription ID:', subscriptionId);
        // Call your API or handler here
    };
    if (!isLoggedIn) {
        return (
            <h1>Please log in to view your licenses.</h1>
        )
    }
    else {
        return (
            <div>
                <div className='desktopOnly'>
                    <Table striped bordered hover>
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr>
                                    {headerGroup.headers.map(column => (
                                        <th style={column.id !== 'tierName' ? { textAlign: 'center' } : {}}>{column.render('Header')}</th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {rows.map(row => {
                                prepareRow(row);
                                return (
                                    <tr>
                                        {row.cells.map(cell => (
                                            <td style={cell.column.id !== 'tierName' ? { textAlign: 'center' } : {}}>{cell.render('Cell')}</td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>
                <div className='mobileOnly'>
                    {rows.map(row => {
                        prepareRow(row);
                        return renderSubscriptionCard(row);
                    })}
                </div>

            </div>
        );
    }


};

export default MySubscriptions;
