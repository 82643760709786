import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { AuthGetRequest } from '../../APIGateWayRequestMiddleWare';
import { Col, Row } from 'react-bootstrap';

const SubgroupForm = (props) => {
    const { orgID, handleClose, show } = props;
    const [formFields, setFormFields] = useState({
        sub_group_type: '',
        group_title: '',
        join_code: '',
        auto_join: false
    });
    const [selectCategory, setSelectedCategory] = useState({
        categoryLabel: '',
        id: '',
        customJoinCode: false,
        defaultAutoJoin: false
    })
    const [orgCategories, setOrgCategories] = useState([]);
    const [isValidJoinCode, setIsValidJoinCode] = useState(true);
    const [isValidGroupName, setIsValidGroupName] = useState(true);

    const validateGroupName = (name) => {
        const regex = /^[a-zA-Z0-9\s\[\]-]+$/;
        const isLengthValid = name.length >= 5;
        return isLengthValid && regex.test(name);
    };
    const validateJoinCode = (code) => {
        const isLengthValid = code.length >= 8;
        const isCharValid = /^[a-zA-Z0-9-_]+$/.test(code);
        return isLengthValid && isCharValid;
    };
    const toggleAutoJoin = () => {
        setFormFields(prevState => ({
            ...prevState,
            auto_join: !prevState.auto_join
        }));
    };
    useEffect(() => {
        if (show) {
            const fetchOrgSubCategories = async () => {
                setOrgCategories([])
                setFormFields({
                    sub_group_type: '',
                    group_title: '',
                    join_code: '',
                    auto_join: false
                })
                const response = await AuthGetRequest(`/v1/organizations/categories?orgId=${orgID}`)
                if (response.status === 200) {
                    console.log(response);
                    setOrgCategories(response.data);
                }
            };
            fetchOrgSubCategories();
        }
    }, [orgID, show]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        setFormFields((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (name === "sub_group_type") {
            if (value !== '') {
                let category = orgCategories.filter(cat => cat.id === value);
                console.log(category)
                setSelectedCategory(category.at(0))
                setFormFields((prevState) => ({
                    ...prevState,
                    auto_join: category.at(0).defaultAutoJoin
                }));
            }
            else{
                setSelectedCategory({
                    categoryLabel: '',
                    id: '',
                    customJoinCode: false,
                    defaultAutoJoin: false
                })
            }

        }
        if (name === "join_code") {
            setIsValidJoinCode(validateJoinCode(value));
        }

        if (name === "group_title") {
            setIsValidGroupName(validateGroupName(value));
        }

    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(`/api/groups/${orgID}/settings/subgroups/create`, formFields);
            if (response.status === 201) {
                console.log('Response', response.data);
                // Handle success or additional logic here
                alert('Successfully created subgroup!');
                window.location.reload();
            }
            handleClose();
        } catch (error) {
            // Handle error here
            console.error('Error:', error);
        }
    };

    return (
        <div>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Create Subgroup</Modal.Title>
                </Modal.Header>
                
                    <Form onSubmit={handleSubmit}>
<Modal.Body>
                        <Form.Group controlId="group_title" className="mb-3">
                            <Form.Label>Group Title</Form.Label>
                            <Form.Control type="text" name="group_title"
                                value={formFields.group_title}
                                onChange={handleInputChange}
                                isInvalid={!isValidGroupName}
                                required />
                            {!isValidGroupName && (
                                <Form.Control.Feedback type="invalid">
                                    Group name must be at least 5 characters long and contain only alphabet characters, Latin characters, spaces, dashes, and square brackets (but not parentheses).
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Form.Group controlId="sub_group_type" className="mb-3">
                            <Form.Label>Subgroup Type</Form.Label>
                            <Form.Select name="sub_group_type"
                                value={formFields.sub_group_type}
                                onChange={handleInputChange}
                                required>
                                <option value="">Select Subgroup Type</option>
                                {orgCategories.map((category) => (
                                    <option key={category.id} value={category.id}>
                                        {category.categoryLabel}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        {selectCategory.customJoinCode && (<Form.Group controlId="join_code" className="mb-3">
                            <Form.Label>Join Code:</Form.Label>
                            <Form.Control type="text" name="join_code"
                                value={formFields.join_code}
                                onChange={handleInputChange} required
                                isInvalid={!isValidJoinCode}
                            />
                            {!isValidJoinCode && (
                                <Form.Control.Feedback type="invalid">
                                    Join code must be at least 8 characters long and contain only letters, numbers, '-', and '_'.
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>)}
                        {selectCategory.customJoinCode && (<Form.Group as={Row} className='mb-2' controlId='auto_join'>
                            <Form.Label column md='3'>Membership auto join</Form.Label>
                            <Col>
                                <Form.Check
                                    type='switch'
                                    name='auto_join'
                                    checked={formFields.auto_join}
                                    onChange={toggleAutoJoin}
                                ></Form.Check>
                            </Col>
                        </Form.Group>)}
                        </Modal.Body>
                        <Modal.Footer>
                <Button variant="primary" type="submit">
                            Create
                        </Button>
                </Modal.Footer>
                    </Form>
                
                
            </Modal>
        </div>
    );
};

export default SubgroupForm;
