import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, InputGroup, FormControl, Button, FormSelect, ToastContainer, Toast, ToastHeader, ToastBody, Alert, AlertHeading } from 'react-bootstrap';
import { UnAuthGetRequest } from '../APIGateWayRequestMiddleWare';
import { getIdToken } from '../APIGateWayRequestMiddleWare';

function HomeScreenLanding(props) {
    const {isLoggedIn, login} = props;
    const [isLoggedInSure, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();
    const [searchType, setSearchType] = useState("OrganizationID"); // default search type
    const [keywords, setKeywords] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    useEffect(()=>{
        setIsLoggedIn(checkLogin);
    },[])
    async function checkLogin(){
        const token = await getIdToken();
        if (token){ return true};
        return false;
    }
    const handleSearch = async () => {
        try {
            const response = await UnAuthGetRequest(`/v1/organizations/search?searchType=${searchType}&keywords=${keywords}`);
            if (response.status === 200) {
                navigate(`/groups/${response.data}/landing-page`);
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setErrorMessage("Organization not found");
            } else {
                console.log(error.message);
                setErrorMessage("An error occurred while searching: " + error.message);
            }
        }
    };

    return (
        <Container>
            <Row className="justify-content-md-center">
                <Col md="auto">
                    <h1>Org Appointments</h1>
                    <h4>Powered by Org Tools</h4>
                    <p>
                        Welcome to Org Appointments, the premiere tool for scheduling and managing meetings within your organization.
                        Easily coordinate with leadership members and streamline your appointment processes with our user-friendly interface.
                    </p>
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col md="auto">
                    <InputGroup className="mb-3">
                        <FormControl
                            placeholder="Find my organization"
                            aria-label="Find my organization"
                            aria-describedby="basic-addon2"
                            onChange={e => setKeywords(e.target.value)}
                        />
                        <FormSelect aria-label="Select search type" onChange={e => setSearchType(e.target.value)}>
                            <option value="OrganizationID">Search by Organization ID</option>
                            <option value="OrganizationName">Search by Organization Name</option>
                        </FormSelect>
                        <Button variant="outline-secondary" onClick={handleSearch}>Search</Button>
                    </InputGroup>
                </Col>
            </Row>
            {errorMessage && (<Alert variant='danger' onClose={() => { setErrorMessage(null) }} dismissible>
                <AlertHeading>Error</AlertHeading>
                {errorMessage}
            </Alert>)}
            {!isLoggedIn && (<Row className="justify-content-md-center">
                <Col md="auto">
                    <Button onClick={login}>Login | Register</Button>
                </Col>
                
            </Row>)}
            
        </Container>
    )
}

export default HomeScreenLanding;
