
import { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import RoleRow from "../../CustomComponents/RoleTable";
import { Trash } from "react-bootstrap-icons";
import RoleForm from "../../CustomComponents/UserViews/RoleCreationModal";
import { getOrgRoleList } from "../../SearchEngine";
import { AuthGetRequest } from "../../APIGateWayRequestMiddleWare";
import SubgroupForm from "../../CustomComponents/UserViews/NewStructure";
import OrgStructure from "../../CustomComponents/OrganizationViewer";

const SubOrganizaionManagementToolbox = (props) => {
    const { orgID, permissions } = props;
    const [displayModal, setAddRole] = useState(false);
    const [organizations, setOrganizations] = useState([]);
    const toggleAddRoleModal = () => {
        setAddRole(!displayModal);
    }
    const RowAction = (role) => {
        const handleDeleteRole = () => {
            alert("delete " + role.id + "?")
        }
        return (
            <>
                <Button variant="danger" onClick={handleDeleteRole}><Trash /></Button>
            </>
        )
    }
    useEffect(() => {
        AuthGetRequest(`/v1/organizations/sub-orgs?orgId=${orgID}`)
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data)
                    setOrganizations(response.data);
                }
            })
    }, [])

    return (
        <>
            <div>
                <h3>Organization Structure:</h3>
                <Button variant="success" onClick={toggleAddRoleModal}>Create Organization</Button>
                <SubgroupForm orgID={orgID} show={displayModal} handleClose={toggleAddRoleModal} />
            </div>
            <Table striped responsive>
                <thead>
                    <tr>
                        <th>Organization Name</th>
                        <th>Date Created</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {organizations.length === 0 ?
                        (<>
                            <tr>
                                <td></td>
                                <td>No organization structures found</td>
                                <td><Button variant='success' disabled={!permissions.includes('Root_Admin') && !permissions.includes('Structure_Manager')}>Add Organization</Button></td>
                            </tr>
                        </>) :
                        (
                            <>
                                {organizations.map(org => (
                                    <OrgStructure org={org} Action={RowAction(org)} permission={permissions} />
                                ))}
                            </>
                        )}
                </tbody>
            </Table>
        </>
    )
}
export default SubOrganizaionManagementToolbox;
