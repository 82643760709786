import './profile.css';
import React, { useState, useRef } from 'react';
import { Container, Form, Button, Row, Col, Image as BootstrapImage } from 'react-bootstrap';
import { PencilSquare } from 'react-bootstrap-icons';

export function UserProfileRootScreen() {

    const fileInputRef = useRef(null);
    const [CroppedFile, setCropFile] = useState(null);
    const [image, setImage] = useState(localStorage.getItem('profile_image_url') || "https://cdn-icons-png.flaticon.com/512/456/456212.png");

    const [profileData, setProfileData] = useState({
        firstName: 'John',
        lastName: 'Doe',
        alias: 'johnnydoe',
        email: 'user@example.com',
        phone: '(555) 123-4567',
        pref_name: 'Jonny Doe',
        isEmailEditable: false,
        isPhoneEditable: false,
        sharingOptions: {
            Email: { organizations: false, public: false, search: false },
            Phone: { organizations: false, public: false, search: false },
        }
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setProfileData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleEditClick = (field) => {
        setProfileData((prevState) => ({
            ...prevState,
            [field]: !prevState[field]
        }));
    };

    // Add this function within the UserProfileRootScreen component
    const updateSharingOption = async (field, option, value) => {
        // Construct the endpoint URL with query parameters
        const endpoint = `/api/update-sharing?field=${field}&option=${option}&value=${value}`;

        // Make an API call to update the sharing option
        try {
            const response = await fetch(endpoint, {
                method: 'POST', // or 'PUT' if your API requires
                headers: {
                    'Content-Type': 'application/json',
                },
                // Include authentication tokens if needed
            });

            if (response.ok) {
                console.log(`Sharing option updated: ${field} ${option} = ${value}`);
                // Handle the response if necessary
            } else {
                console.error('Failed to update sharing option:', response.statusText);
                // You may want to revert the checkbox change if the API call fails
            }
        } catch (error) {
            console.error('Error updating sharing option:', error);
            // Again, consider reverting the checkbox state if an error occurs
        }
    };

    // Modify handleCheckboxChange to use the new API call function
    const handleCheckboxChange = (field, option) => {
        
        setProfileData((prevState) => {
            const newValue = !prevState.sharingOptions[field][option];
            // Make the API call
            updateSharingOption(field, option, newValue);
            return {
                ...prevState,
                sharingOptions: {
                    ...prevState.sharingOptions,
                    [field]: {
                        ...prevState.sharingOptions[field],
                        [option]: newValue,
                    },
                },
            };
        });
    };




    const handleSubmit = (event) => {
        event.preventDefault();
        // Here you would typically handle the form submission, making an API call to update the user's profile
        console.log('Profile data to be submitted', profileData);
    };

    const uploadImage = async () => {
        //Loading Logo

        console.log("Uploading image...");
        //await uploadUserImage(CroppedFile);

        window.location.reload();
    }
    const handleImageChange = e => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            reader.onloadend = () => {
                const img = new Image();
                img.src = reader.result;
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    const size = Math.min(img.width, img.height);
                    canvas.width = size;
                    canvas.height = size;
                    context.drawImage(
                        img,
                        (img.width - size) / 2,
                        (img.height - size) / 2,
                        size,
                        size,
                        0,
                        0,
                        size,
                        size
                    );
                    canvas.toBlob((blob) => {
                        // convert the blob to a file
                        const croppedFile = new File([blob], file.name, { type: file.type });
                        // call your upload image function with the cropped file
                        setCropFile(croppedFile);
                    }, file.type);
                    const dataUrl = canvas.toDataURL(file.type);
                    setImage(dataUrl);
                };
            };
        };
    }
    const handleFileButtonClick = () => {
        fileInputRef.current.click();
    };

    function DesktopUserProfile() {
        return (
            <Container>
                <Form onSubmit={handleSubmit}>
                    <Row className="align-items-center">
                        <Col xs={12} md={3}>
                            <BootstrapImage src={image} alt="Profile" roundedCircle className='profile-image' />
                            <div className="mt-3">
                                <input type="file" onChange={handleImageChange} required ref={fileInputRef} accept="image/png, image/jpeg" className="d-none" />
                                <button className="btn btn-secondary mt-2" onClick={handleFileButtonClick} enabled>Upload Profile Photo</button>
                                {CroppedFile !== null && (<button id='imageUploader' className="btn btn-primary mt-2 ms-2" onClick={uploadImage} disabled={!CroppedFile}>Upload Image</button>)}
                            </div>
                        </Col>
                        <Col xs={12} md={9}>
                            <h1>{profileData.firstName} {profileData.lastName}</h1>
                            <small>@{profileData.alias}</small>
                            <br /><br />
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column md="2">Email</Form.Label>
                                <Col md="8">
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        value={profileData.email}
                                        onChange={handleInputChange}
                                        readOnly={!profileData.isEmailEditable}
                                    />
                                </Col>
                                <Col md="2">
                                    {profileData.isEmailEditable ? (
                                        <Button variant="outline-primary" onClick={() => handleEditClick('isEmailEditable')}>Save</Button>
                                    ) : (
                                        <PencilSquare onClick={() => handleEditClick('isEmailEditable')} />
                                    )}
                                </Col>
                            </Form.Group>
                            <Row>
                                <Col md={{ span: 8, offset: 2 }}>
                                    <Form.Check
                                        label="Share with my Organizations"
                                        name="emailOrganizations"
                                        checked={profileData.sharingOptions.Email.organizations}
                                        onChange={() => handleCheckboxChange('Email', 'organizations')}
                                    />
                                    <Form.Check
                                        label="Share Publicly"
                                        name="emailPublic"
                                        checked={profileData.sharingOptions.Email.public}
                                        onChange={() => handleCheckboxChange('Email', 'public')}
                                    />
                                    <Form.Check
                                        label="Allow Search With"
                                        name="emailSearch"
                                        checked={profileData.sharingOptions.Email.search}
                                        onChange={() => handleCheckboxChange('Email', 'search')}
                                    />
                                </Col>
                            </Row>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column md="2">Phone</Form.Label>
                                <Col md="8">
                                    <Form.Control
                                        type="tel"
                                        name="phone"
                                        value={profileData.phone}
                                        onChange={handleInputChange}
                                        readOnly={!profileData.isPhoneEditable}
                                    />
                                </Col>
                                <Col md="2">
                                    {profileData.isPhoneEditable ? (
                                        <Button variant="outline-primary" onClick={() => handleEditClick('isPhoneEditable')}>Save</Button>
                                    ) : (
                                        <PencilSquare onClick={() => handleEditClick('isPhoneEditable')} />
                                    )}
                                </Col>
                            </Form.Group>
                            <Row>
                                <Col md={{ span: 8, offset: 2 }}>
                                    <Form.Check
                                        label="Share with my Organizations"
                                        name="phoneOrganizations"
                                        checked={profileData.sharingOptions.Phone.organizations}
                                        onChange={() => handleCheckboxChange('Phone', 'organizations')}
                                    />
                                    <Form.Check
                                        label="Share Publicly"
                                        name="phonePublic"
                                        checked={profileData.sharingOptions.Phone.public}
                                        onChange={() => handleCheckboxChange('Phone', 'public')}
                                    />
                                    <Form.Check
                                        label="Allow Search With"
                                        name="phoneSearch"
                                        checked={profileData.sharingOptions.Phone.search}
                                        onChange={() => handleCheckboxChange('Phone', 'search')}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Container>
        )
    }

    function MobileUserProfile() {
        return (
            <Container className="pt-4">
                <Form onSubmit={handleSubmit} className="text-center">
                    <div>
                        <BootstrapImage src={image} alt="Profile" roundedCircle className='profile-image' />
                        <input type="file" onChange={handleImageChange} required ref={fileInputRef} accept="image/png, image/jpeg" className="d-none" />
                        <button className="btn btn-secondary mt-2" onClick={handleFileButtonClick} enabled>Upload Profile Photo</button>
                        {CroppedFile !== null && (<button id='imageUploader' className="btn btn-primary mt-2 ms-2" onClick={uploadImage} disabled={!CroppedFile}>Upload Image</button>)}
                    </div>
                    <h1 className="mt-4">{profileData.firstName} {profileData.lastName}</h1>
                    <small>@{profileData.alias}</small>

                    <Form.Group className="mb-3 mt-3">
                        <Form.Control
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={profileData.email}
                            onChange={handleInputChange}
                            readOnly={!profileData.isEmailEditable}
                        />
                        <PencilSquare className="edit-icon" onClick={() => handleEditClick('isEmailEditable')} />
                    </Form.Group>
                    {profileData.isEmailEditable && <Button variant="outline-primary" onClick={() => handleEditClick('isEmailEditable')}>Save</Button>}

                    <Form.Group className="mb-3">
                        <Form.Control
                            type="tel"
                            name="phone"
                            placeholder="Phone"
                            value={profileData.phone}
                            onChange={handleInputChange}
                            readOnly={!profileData.isPhoneEditable}
                        />
                        <PencilSquare className="edit-icon" onClick={() => handleEditClick('isPhoneEditable')} />
                    </Form.Group>
                    {profileData.isPhoneEditable && <Button variant="outline-primary" onClick={() => handleEditClick('isPhoneEditable')}>Save</Button>}

                    <h2 className="mt-4">Contact Sharing Options</h2>
                    {Object.entries(profileData.sharingOptions).map(([field, options]) => (
                        <div key={field}>
                            <h3>{field}</h3>
                            {Object.entries(options).map(([option, value]) => (
                                <Form.Group key={option} className="mb-3">
                                    <Form.Check
                                        type="switch"
                                        id={`${field}-${option}-toggle`}
                                        label={option.charAt(0).toUpperCase() + option.slice(1)} // Capitalize the label
                                        checked={value}
                                        onChange={() => handleCheckboxChange(field, option)}
                                    />
                                </Form.Group>
                            ))}
                        </div>
                    ))}
                </Form>
            </Container>
        )
    }
    return (
        <div>
            <h1>My Profile</h1>
            <div className="desktop-ui">
                <DesktopUserProfile />
            </div>
            <div className="mobile-ui">
                <MobileUserProfile />
            </div>
        </div>
    )
}