import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Card, Button, Modal, Spinner } from 'react-bootstrap';
import { Hub } from 'aws-amplify';
import { AuthPostRequest, UnAuthGetRequest } from '../APIGateWayRequestMiddleWare';

export function RenderedProductSubscription({ product, stripePromise }) {
    const [showFeatures, setShowFeatures] = useState(false);
    const [features, setFeatures] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState(null);
    const [isLoadingCheckout, setCheckoutLoading] = useState(false);
    const [isLoadingCheckouts, setIsLoadingCheckouts] = useState({}); // Maintain loading state for each price option


    useEffect(() => {
        // Check the initial authentication state when the component mounts
        checkUser();

        // Subscribe to authentication events
        const authSubscription = Hub.listen('auth', ({ payload }) => {
            if (payload.event === 'signIn') {
                // User has signed in
                checkUser();
            } else if (payload.event === 'signOut') {
                // User has signed out
                setUser(null);
            }
        });

        // Clean up the subscription when the component unmounts
        return () => {
            authSubscription();
        };
    }, []);

    async function checkUser() {
        try {
            await Auth.currentAuthenticatedUser();
            setIsLoggedIn(true); // User is authenticated
        } catch (error) {
            setIsLoggedIn(false); // No user is signed in
        }
    }

    async function login() {
        window.localStorage.setItem('login-redirect-path', window.location.pathname);
        window.location.href = '/login';
    }

    const handleSubscribe = (stripePriceId, stripeProductId) => {
        setCheckoutLoading(true);
        setIsLoadingCheckouts(prevLoadingState => ({
            ...prevLoadingState,
            [stripePriceId]: true,
        }));
        const url = `/v1/subscriptions/new-subscription?stripeProductId=${stripeProductId}&stripePriceId=${stripePriceId}`;

        AuthPostRequest(url, '{}')
            .then(response => response.data)
            .then(data => {
                if (data.checkoutSessionId) {
                    // Using Stripe's JS library to redirect to the Checkout page
                    const redirectToCheckout = async () => {
                        const stripe = await stripePromise;
                        const { error } = await stripe.redirectToCheckout({
                            sessionId: data.checkoutSessionId,
                        });

                        if (error) {
                            console.error('Redirect to Stripe checkout failed:', error);
                        }
                    };

                    redirectToCheckout();
                } else {
                    console.error('Checkout session ID not found in the response');
                }
            })
            .catch(error => {
                console.error('Error:', error);
            })
            .finally(() => {
                // Reset the loading state after the operation is complete
                setIsLoadingCheckouts(prevLoadingState => ({
                    ...prevLoadingState,
                    [stripePriceId]: false,
                }));
                // Reset the loading state for all buttons
                setCheckoutLoading(false);
            });
    };

    const handleViewFeatures = stripeProductId => {
        const url = `/v1/subscriptions/product/access-features?stripeProductId=${stripeProductId}`;
        UnAuthGetRequest(url)
            .then(response => response.json())
            .then(data => {
                // Assuming the response contains a 'features' array
                setFeatures(data.features);
                setShowFeatures(true);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    return (
        <div className="product-card">
            <h2 className="product-title">{product.productName}</h2>
            <p className="product-description">{product.productDescription || 'No description available.'}</p>
            {product.priceOptions.map(priceOption => (
                <div className="price-option" key={priceOption.stripePriceId}>
                    <span>${priceOption.price} / {priceOption.interval} {priceOption.currency}</span>
                    {isLoggedIn && (
                        <Button
                            variant='success'
                            onClick={() => handleSubscribe(priceOption.stripePriceId, product.stripeProductId)}
                            disabled={isLoadingCheckout}
                        >
                            {isLoadingCheckouts[priceOption.stripePriceId] ? (
                                <>
                                    <Spinner size='sm' animation="border" role="status" /> Subscribing
                                </>
                            ) : (
                                <>Subscribe</>
                            )}
                        </Button>
                    )}
                    {!isLoggedIn && (
                        <Button variant='primary' onClick={login}>
                            <div>Login</div> <small>to Subscribe</small>
                        </Button>
                    )}
                </div>
            ))}
            <a className="view-features" onClick={() => handleViewFeatures(product.stripeProductId)} style={{ backgroundColor: '#0a2a5279' }}>View Features</a>
            <Modal show={showFeatures} onHide={() => setShowFeatures(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Features</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul>
                        {features.map((feature, index) => (
                            <li key={index}>{feature}</li>
                        ))}
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowFeatures(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
