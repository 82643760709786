import { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { AuthGetRequest, AuthPostRequest } from '../../APIGateWayRequestMiddleWare';
import { Spinner } from '../../CustomComponents/Spinners/Spinner';
import { Alert, Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { Clipboard, Eye, EyeSlash, PencilSquare, Search } from 'react-bootstrap-icons';
import moment from 'moment-timezone';
import { TableBody } from '@aws-amplify/ui-react';
import { Spinner as ReactSpinner } from 'react-bootstrap';


const ToolboxOverview = (props) => {
    const { orgID, permissions } = props;
    //Licenses related to the organization
    const [parentLicenses, setParentLicenses] = useState([]);
    const [licenses, setLicenses] = useState([]);

    const [showOrgID, setOrgIdVisible] = useState(false);
    const [isValidJoinCode, setIsValidJoinCode] = useState(true);
    const [isValidGroupName, setIsValidGroupName] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [isGroupNameEditable, setIsGroupNameEditable] = useState(false);
    const [isJoinCodeEditable, setIsJoinCodeEditable] = useState(false);
    const [shouldUploadChanges, setShouldUploadChanges] = useState(false);

    const [groupData, setGroupData] = useState({
        groupName: '',
        groupJoinCode: '',
        originalGroupData: null,
        auto_join: null
    });

    const convertToLocalTime = (Time) => {
        if (!Time) { return 'Formatting Error'; }
        const localTime = moment.utc(Time, 'MMM D, YYYY, h:mm:ss A').tz(moment.tz.guess());
        return localTime.format('MMM D, YYYY, h:mm A z');
    }


    useEffect(() => {
        setIsLoading(true)
        AuthGetRequest(`/v1/organizations/organization-configuration?orgId=${orgID}`)
            .then((response) => {
                if (response.status === 200) {
                    setGroupData(response.data.orgData);
                }
                else {
                    setGroupData(response.response.data);
                }
                console.log(response)

            })
            .catch((error) => {
                console.log(error);
            }).finally(()=>{
                setIsLoading(false);

            })
        //Get the parent License
        //AuthGetRequest(`/v1/organizations/organization-configuration?orgId=${orgID}`)
    }, [])

    useEffect(() => {
        if (shouldUploadChanges) {
            uploadChanges();
            setShouldUploadChanges(false); // Reset the flag after uploading changes
        }
    }, [shouldUploadChanges]);

    const validateGroupName = (name) => {
        const regex = /^[a-zA-Z0-9\s\[\]-]+$/;
        const isLengthValid = name.length >= 5;
        return isLengthValid && regex.test(name);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setGroupData((prevState) => ({
            ...prevState,
            [name]: value,
        }));

        if (name === "groupJoinCode") {
            setIsValidJoinCode(validateJoinCode(value));
        }

        if (name === "groupName") {
            setIsValidGroupName(validateGroupName(value));
        }
    };

    const handleEditClick = (field) => {
        console.log(groupData)
        if (field === "isGroupNameEditable" || field === "isJoinCodeEditable") {
            setGroupData((prevState) => ({
                ...prevState,
                originalGroupData: { ...prevState }, // Save the original data before editing
            }));
            if (field === "isGroupNameEditable") {
                setIsGroupNameEditable(!isGroupNameEditable);
            }
            else if (field === "isJoinCodeEditable") {
                setIsJoinCodeEditable(!isJoinCodeEditable);
            }
        }

    };

    const handleSaveClick = (field) => {
        if (field === "isGroupNameEditable") {
            setIsGroupNameEditable(!isGroupNameEditable);
        }
        else if (field === "isJoinCodeEditable") {
            setIsJoinCodeEditable(!isJoinCodeEditable);
        }
        setShouldUploadChanges(true); // Indicate that changes should be uploaded
    };
    const uploadChanges = () => {
        const data = {
            id: groupData.id,
            groupName: groupData.groupName,
            groupJoinCode: groupData.groupJoinCode,
            auto_join: groupData.auto_join
        }
        AuthPostRequest(`/v1/organizations/organization-configuration`, data)
            .then((response) => {
                // Handle the response, e.g., show a success message
            })
            .catch((error) => {
                // Handle the error, e.g., show an error message
            });

    }
    const handleCancelClick = (field) => {
        if (field === "isGroupNameEditable") {
            setIsGroupNameEditable(!isGroupNameEditable);
        }
        else if (field === "isJoinCodeEditable") {
            setIsJoinCodeEditable(!isJoinCodeEditable);
        }
        if (groupData.originalGroupData) {
            // Restore the original data when cancel is clicked
            setGroupData(groupData.originalGroupData);
        }

    };
    const validateJoinCode = (code) => {
        const isLengthValid = code.length >= 8;
        const isCharValid = /^[a-zA-Z0-9-_]+$/.test(code);
        return isLengthValid && isCharValid;
    };

    if (groupData.message) {
        return (
            <>
                <h3>Organization Details:</h3>
                <Alert variant='danger'>{groupData.message}</Alert>
            </>
        )
    }
    const toggleAutoJoin = () => {
        setGroupData(prevState => ({
            ...prevState,
            auto_join: !prevState.auto_join
        }));
        setShouldUploadChanges(true); // Indicate that changes should be uploaded
    };
    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            console.log('Text successfully copied to clipboard');
        } catch (err) {
            console.error('Failed to copy text to clipboard', err);
        }
    }

    const SearchForLicenses = (props) => {

        const { setLicenseFound } = props;
        const [isSearching, setIsSearching] = useState(false);
        const [isSearchEnabled, setSearchEnable] = useState(false);
        const [isCodeValid, setCodeValid] = useState(true);
        const [subscriptionID, setSubscriptionID] = useState('');
        const [errorMsg, setErrMsg] = useState('');
        const handleSubscriptionCodeChange = (event) => {
            const { value } = event.target;

            setSubscriptionID(value);
            setCodeValid(validateJoinCode(value));
        }
        const handleCancel = ()=>{
            setSubscriptionID('');
            setLicenseFound({})
            setSearchEnable(false);
        }
        const handleSubmit = (event) => {
            event.preventDefault();
            setErrMsg('');
            setIsSearching(true);
            setLicenseFound({
                subscriptionId: '',
            });

            if (isCodeValid) {
                AuthGetRequest(`/v1/subscriptions/search?subscriptionId=${subscriptionID}`)
                    .then((response) => {
                        if (response.status === 200) {
                            const data = response.data;
                            data.loaded = true;
                            setLicenseFound(data);
                        } else {
                            setErrMsg(response.response.data.message);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => {
                        setIsSearching(false);
                    });
            } else {
                setErrMsg('Invalid subscription id format, check that there are no spaces!');
            }
        };

        return (
            <>
                <Form onSubmit={handleSubmit}>
                    <Form.Group as={Row} controlId='searchBar' className='mb-3'>
                        {isSearchEnabled && (
                            <>
                                <Form.Label >Subscription ID: </Form.Label>
                                <Col md='6'>
                                    <Form.Control className='mb-3'
                                        type='text'
                                        placeholder='Enter subscription id'
                                        name='seachSubID'
                                        onChange={handleSubscriptionCodeChange}
                                        value={subscriptionID}
                                        isInvalid={!isCodeValid}
                                        readOnly={!isSearchEnabled || isSearching}
                                    />
                                    {!isCodeValid && (
                                        <Form.Control.Feedback type="invalid">
                                            Invalid subscription id format, check that there are no spaces!
                                        </Form.Control.Feedback>
                                    )}
                                </Col>
                                {errorMsg && <Alert variant='danger'>{errorMsg}</Alert>}
                            </>
                        )}
                        <Col>
                            {!isSearchEnabled ? (
                                <Button onClick={() => { setSearchEnable(true) }}>Look up License</Button>
                            ) : (
                                <Button onClick={() => { handleCancel() }} disabled={isSearching} variant='danger'>Cancel</Button>
                            )}
                        </Col>
                        <Col>
                            {isSearchEnabled && (
                                <Button type='submit' disabled={!isCodeValid || isSearching}>{isSearching ? (<ReactSpinner size='sm' />) : (<Search />)}</Button>
                            )}
                        </Col>
                    </Form.Group>
                </Form>
            </>
        )


    }

    const RootPlanPopup = (props) => {
        const { orgID, permissions } = props
        const [showModal, setShowModal] = useState(false);
        const [editLicenseEnabled, setEdit] = useState(false);
        const [currentLicense, setCurrentLicense] = useState({})
        const [replacementLicense, setReplacementLicense] = useState({
        });
        const [selectedLicense, setSelectedLicense] = useState({})
        const [searchedLicense, setSearchedLicense] = useState({});


        const toggleModal = () => {
            setShowModal(!showModal);
        }

        useEffect(()=>{
            if(showModal){
                AuthGetRequest(`/v1/subscriptions/root-license-org?org-id=${orgID}`)
            .then((response)=>{
                if(response.status === 200){
                    setCurrentLicense(response.data);
                }
                else{
                    console.log(response.request.data);
                }
            })
            }
            
        },[orgID, showModal])

        const [subscriptions, setSubscriptions] = useState([]);
        const [isLoading, setIsLoading] = useState(true);
        const handleSelectChange = (event) => {
            setSelectedLicense(prevState => ({
                ...prevState,
                subscriptionId: event.target.value
            }));

            const newSelect = subscriptions.filter(sub=> sub.subscriptionId === event.target.value);
            console.log(newSelect);
            setReplacementLicense(newSelect.at(0))
        };

        const fetchSubscriptions = async () => {
            try {
                const response = await AuthGetRequest('/v1/user/available-licenses');
                setSubscriptions(response.data);
                console.log(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error('Failed to fetch subscriptions:', error);
                setIsLoading(false);
            }
        };
        useEffect(() => {
            if(showModal){
                fetchSubscriptions();
            }
        }, [showModal]);
        useEffect(() => {
            setReplacementLicense(searchedLicense)
        }, [searchedLicense])

        const cancelReplace = ()=>{
            setSelectedLicense(prevState => ({
                ...prevState,
                subscriptionId: ''
            }));
            //clear selections
            setReplacementLicense({});
            //close enable
            setEdit(false);
        }

        return (
            <>
                <Modal onHide={toggleModal} show={showModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Organization Primary License</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h4>Current License</h4>
                        <LicenseViewer license={currentLicense} />

                    </Modal.Body>
                    <Modal.Body>
                        {editLicenseEnabled && (
                            <>
                                <h5>Search For License</h5>
                                <SearchForLicenses setLicenseFound={setSearchedLicense} />
                                <h5>Your available licenses: </h5>
                                <Form.Group controlId="subscriptionId">
                                    <Form.Label>Subscription License</Form.Label>
                                    <Form.Control
                                        as="select"
                                        onChange={handleSelectChange}
                                        value={selectedLicense.subscriptionId  || ''}
                                    >
                                        <option value= "" >Select Subscription</option>
                                        {isLoading ? (
                                            <option value="">Loading...</option>
                                        ) : (
                                            subscriptions.map((subscription, index) => {
                                                // Check if the subscription is in the array
                                                const isInArray = subscriptions.some(sub => sub.subscriptionId === subscription.subscriptionId);

                                                return (
                                                    <option
                                                        key={index}
                                                        value={subscription.subscriptionId}
                                                        disabled={!isInArray || subscription.isRedeemed}
                                                    >
                                                        {subscription.tierName} ({subscription.tierType}) {subscription.subscriptionEnd && (<>Expires {subscription.subscriptionEnd}</>)}
                                                    </option>
                                                );
                                            })
                                        )}
                                    </Form.Control>
                                </Form.Group>

                            </>
                        )}
                        {editLicenseEnabled && replacementLicense.subscriptionId && (<>
                            <h4>Replacement License Details</h4>
                            <LicenseViewer license={replacementLicense} />
                        </>)}


                    </Modal.Body>
                    <Modal.Footer>
                        {!editLicenseEnabled ? (<Button onClick={() => { setEdit(!editLicenseEnabled) }} variant='info'>Replace Primary License</Button>)
                        : (<>
                        <Button variant='danger' onClick={()=>{cancelReplace()}}>Cancel</Button>
                        <Button variant='success' disabled>Replace License</Button>
                        </>)
                    }
                    </Modal.Footer>

                </Modal>
                <Button onClick={toggleModal}> View Primary License </Button>
            </>
        )
    }

    const LicenseViewer = ({ license }) => {

        return (
            <>
                <div>
                    <strong>Plan Name: </strong>{license.tierName}
                </div>
                <div>
                    <strong>Description: </strong>
                    <Alert variant='secondary'>{license.tierDescription ? (<>{license.tierDescription}</>) : (<>No Description Available</>)}</Alert>
                </div>
                <div>
                    <strong>License Type: </strong>{license.tierType}
                </div>
                <div>
                    <strong>License Usage:</strong> {license.isActive ? (<>{!license.isRedeemed && license.isActive ? (<>Avilable to Redeem</>) : (<>In use</>)}</>) : (<>License Inactive</>)}
                </div>
            </>
        )
    }



    return (
        <>
            <Container fluid>
                <h1>Overview</h1>
                <Table borderless>
                    <TableBody>
                        <Row>
                            <Col md='8' >
                                <h3>Organization Details:</h3>
                                <Form>
                                    <Form.Group as={Row} className="mb-3" controlId="groupName">
                                        <Form.Label column md="3">Organization Name</Form.Label>
                                        <Col md='6'>
                                            {!isLoading ? (<Form.Control
                                                type="text"
                                                placeholder="Enter Organization Name"
                                                name="groupName"
                                                onChange={handleInputChange}
                                                value={groupData.groupName}
                                                isInvalid={!isValidGroupName}
                                                readOnly={!isGroupNameEditable}
                                            />):(<Col md='6'><ReactSpinner/></Col>)}
                                            {!isValidGroupName && (
                                                <Form.Control.Feedback type="invalid">
                                                    Group name must be at least 5 characters long and contain only alphabet characters, Latin characters, spaces, dashes, and square brackets (but not parentheses).
                                                </Form.Control.Feedback>
                                            )}
                                        </Col>
                                        <Col>
                                            {isGroupNameEditable ? (
                                                <>
                                                    <Button variant="outline-primary" onClick={() => handleSaveClick('isGroupNameEditable')} disabled={!isValidGroupName} style={{ marginRight: '5px' }}>Save</Button>
                                                    <Button variant="outline-danger" onClick={() => handleCancelClick('isGroupNameEditable')}>Cancel</Button>
                                                </>
                                            ) : (
                                                <PencilSquare onClick={() => handleEditClick('isGroupNameEditable')} />
                                            )}
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="groupJoinCode">
                                        <Form.Label column md="3">Organization Join Code</Form.Label>
                                        <Col md='4'>
                                            {!isLoading ? (<Form.Control
                                                type="text"
                                                placeholder="Enter Join Code"
                                                name="groupJoinCode"
                                                onChange={handleInputChange}
                                                value={groupData.groupJoinCode}
                                                isInvalid={!isValidJoinCode}
                                                readOnly={!isJoinCodeEditable}
                                            />):(<Col md='6'><ReactSpinner/></Col>)}
                                        </Col>
                                        {!isValidJoinCode && (
                                            <Form.Control.Feedback type="invalid">
                                                Join code must be at least 8 characters long and contain only letters, numbers, '-', and '_'.
                                            </Form.Control.Feedback>
                                        )}
                                        <Col>
                                            {isJoinCodeEditable ? (
                                                <>
                                                    <Button variant="outline-primary" onClick={() => handleSaveClick('isJoinCodeEditable')} disabled={!isValidJoinCode} style={{ marginRight: '5px' }}>Save</Button>
                                                    <Button variant="outline-danger" onClick={() => handleCancelClick('isJoinCodeEditable')}>Cancel</Button>
                                                </>
                                            ) : (
                                                <PencilSquare onClick={() => handleEditClick('isJoinCodeEditable')} />
                                            )}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className='mb-2' controlId='auto_join'>
                                        <Form.Label column md='3'>Membership auto join</Form.Label>
                                        <Col>
                                            {!isLoading ?(<Form.Check
                                                type='switch'
                                                name='auto_join'
                                                checked={groupData.auto_join}
                                                onChange={toggleAutoJoin}
                                                disabled={!groupData.canEditGroupJoinCode}
                                            ></Form.Check>):(<Col md='6'><ReactSpinner/></Col>)}
                                        </Col>
                                    </Form.Group>

                                </Form>
                            </Col>
                            {!isLoading ?(<Col md='4'>
                                <h3>Meta Data</h3>
                                <div>
                                    <strong>Group ID: </strong>
                                    {showOrgID ? (
                                        <>
                                            {groupData.id} <Clipboard onClick={() => { copyToClipboard(groupData.id) }} /> <EyeSlash color='#0a2a52' onClick={() => { setOrgIdVisible(false) }} />
                                        </>

                                    ) : (
                                        <>
                                            <span> Hidden </span>
                                            <Eye color='#0a2a52' onClick={() => { setOrgIdVisible(true) }} />
                                        </>
                                    )}
                                </div>
                                <div><strong>Group Type:</strong> {groupData.groupType}</div>
                                <div><strong>Date created:</strong> {convertToLocalTime(groupData.creation_date)} </div>
                                {(<Col>
                                    <h4>Root License Info</h4>
                                    <RootPlanPopup  orgID={orgID} permissions={permissions} />
                                </Col>)}
                            </Col>) : (<Col md='4'>
                                <h3>Meta Data</h3>
                                <Spinner/>
                                </Col>)}

                        </Row>
                        {/* <Row>
                            <h3>Add-ons:</h3>
                            <LicenseTable LicenseList={licenses} />
                        </Row> */}
                    </TableBody>
                </Table>

            </Container>
        </>
    )
}



const LicenseTable = ({ LicenseList }) => {
    if (LicenseList.length === 0) {
        return (
            <div style={{ height: "100%" }}>
                <Table striped responsive>
                    <thead>
                        <tr>
                            <th>License Name</th>
                            <th>License Type</th>
                            <th>Features</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                    </tbody>
                </Table>
                <Spinner />
            </div>);

    }
    else {
        return (
            <Container>
                <div>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>License Name</th>
                                <th>License Type</th>
                                <th>Features</th>
                                <th>Actions</th>
                            </tr>
                        </thead>


                        <tbody>

                        </tbody>
                    </Table>
                </div>
            </Container>
        )
    }

}
export default ToolboxOverview;