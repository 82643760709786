import { useState, useEffect, useRef } from "react";
import { Spinner } from "../../CustomComponents/Spinners/Spinner";
import UserCard from "../../CustomComponents/UserViews/UserCard";
import { Button, Modal } from "react-bootstrap";
import { ArrowLeft, Backspace, CalendarCheck, CalendarWeek, CalendarWeekFill, Chat, ChatFill, Envelope, EnvelopeFill, Messenger, Phone, Plus, PlusCircle, Send, SendFill, Telephone, TelephoneFill, Trash, X } from "react-bootstrap-icons";
import SelectUser from "../../CustomComponents/UserViews/SelectUserGroup";


const AppointmentToolboxConfig = (props) => {
    const { orgID, permissions } = props;



    return (
        <>
            <h1>Appointment Toolbox Configuration</h1>
            <Providers orgID={orgID} permissions={permissions} />
        </>
    )
}


const Providers = (props) => {
    const { orgID, permissions, orgAccess } = props;
    const [providers, setProviders] = useState([]);
    const [viewMode, setViewMode] = useState('card');
    const [isLoading, setLoading] = useState(false);
    const [showAddProviderModal, setAddProviderModal] = useState(false);

    const hideModal = () => {
        setAddProviderModal(false);
    }
    const showModal = () => {
        setAddProviderModal(true);
    }


    const user = {
        first_name: 'Elias',
        last_name: 'Sanabria',
        profile_image: 'https://organization-tools-user-profile-images.s3.us-east-2.amazonaws.com/bvT1woGrULdFnbwmu61u1y790Rg1.jpeg',
        alias: 'elias',
        roles: [
            'Assist Exec Secretary',
            'Ward Clerk',
            'Stake Pres.'
        ]

    }

    function actions(userID) {

        return (
            <div className="btn-group">
                <Button variant="danger">
                    <Trash />
                </Button>
                <Button>
                    <CalendarCheck /> View Appts
                </Button>
                <Button>
                    <CalendarWeek /> Availability
                </Button>
            </div>
        );
    }

    function AddProviderAction() {
        const [buttonSize, setButtonSize] = useState(0);
        const buttonRef = useRef(null);

        useEffect(() => {
            if (buttonRef.current) {
                setButtonSize(buttonRef.current.offsetWidth);
            }
        }, []);

        return (
            <>
                <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: '100%' }}>
                    <Button
                        variant="secondary"
                        ref={buttonRef}
                        style={{
                            borderRadius: "50%",
                            width: "90%",
                            height: `${buttonSize}px`, // Set the height equal to the calculated width
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                        onClick={showModal}
                    >
                        <PlusCircle size={90} />
                    </Button>
                    <div className="mt-2">Add Provider</div>
                </div>
            </>
        );
    }
    const AddProviderModal = () => {
        const [selectedUser, setSelectedUser] = useState(null);

        const clearSelectedUser = () => {
            setSelectedUser(null);
        }
        const AddProviderActions = (user) => {
            const handleCall = () => {
                window.open(`tel:${user.phoneNumber}`, '_blank');
            };

            const handleSMS = () => {
                window.open(`sms:${user.phoneNumber}`, '_blank');
            };

            const handleEmail = () => {
                window.open(`mailto:${user.emailAddress}`, '_blank');
            };

            return (
                <>
                    <div><strong>Contact Methods:</strong></div>
                    <div className="btn-group mb-2">
                        {user.phoneNumber && (
                            <Button variant="success" onClick={handleCall}>
                                <TelephoneFill />
                            </Button>
                        )}
                        {/* SMS */}
                        {user.phoneNumber && (
                            <Button variant="primary" onClick={handleSMS}>
                                <ChatFill />
                            </Button>
                        )}
                        {/* Direct Messaging */}
                        {/* Direct Messaging button remains as is since you don't want an onClick handler for it */}
                        {user.sub_id && (
                            <Button variant="imessage" disabled>
                                <SendFill color="white" />
                            </Button>
                        )}

                        {user.emailAddress && (
                            <Button variant="primary" onClick={handleEmail}>
                                <EnvelopeFill color="white" />
                            </Button>
                        )}
                    </div>
                    <Button variant="secondary" onClick={clearSelectedUser}>
                        <ArrowLeft /> Back to List
                    </Button>
                </>
            );
        }
        const permissions = ['Root_Admin', 'Provider'];
        const permissionsParam = JSON.stringify(permissions);

        return (
            <Modal show={showAddProviderModal} onHide={hideModal} centered>
                <Modal.Header closeButton><Modal.Title>Add Provider</Modal.Title></Modal.Header>
                <Modal.Body>
                    <SelectUser url={`/v1/organizations/search?searchType=OrgRolePermissionActiveSearch&orgId=${orgID}&permissions=${permissionsParam}`} setSelectedUser={setSelectedUser} selectedUser={selectedUser} />
                    {selectedUser && (
                        <UserCard user={selectedUser} Actions={AddProviderActions(selectedUser)} />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {permissions.includes("Schedule_Manager") || permissions.includes("Root_Admin") && selectedUser !== null && (
                        <Button variant="add"><Plus />  Provider</Button>
                    )}
                    <Button onClick={hideModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }


    if (isLoading) {
        return (
            <>

                <h3>Providers:</h3>
                <Spinner message={'Getting Providers'} />
            </>
        )
    }
    return (
        <>
            <AddProviderModal />
            <h3>Providers:</h3>
            {viewMode === 'card' ? (
                <>
                    <div className="d-flex flex-wrap">
                        <UserCard user={user} Actions={actions()} />
                        <UserCard Actions={AddProviderAction()} />
                    </div>
                </>
            ) : (
                <></>
            )}
        </>
    )
}
export default AppointmentToolboxConfig;