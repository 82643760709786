import { Auth } from 'aws-amplify';
import axios from 'axios';

export async function getIdToken() {
    try {
        const session = await Auth.currentSession();
        return session.getIdToken().getJwtToken();
    } catch (error) {
        console.error("Error getting ID token", error);
        return null;
    }
}

export async function AuthGetRequest(endpoint) {
    const token = await getIdToken();
    if (!token) {
        return; // or handle the case where there's no token available
    }
    try {
        const response = await axios.get(`https://api.orgtools.app${endpoint}`, {
          headers: {
            Authorization: "Bearer " + token
          }
        });
        return response
      } catch (error) {
        console.error("Error fetching data", error);
        return error;
      }
}
export async function AuthPostRequest(endpoint, body) {
  const token = await getIdToken();
    if (!token) {
        return; // or handle the case where there's no token available
    }
    try {
        const response = await axios.post(`https://api.orgtools.app${endpoint}`, body, {
          headers: {
            Authorization: "Bearer " + token
          }
        });
        return response;
    } catch (error) {
        console.error("Error posting data", error);
        return error;
    }
}
export async function AuthDeleteRequest(endpoint){
  const token = await getIdToken();
    if (!token) {
        return; // or handle the case where there's no token available
    }
    try {
      const response = await axios.delete(`https://api.orgtools.app${endpoint}`, {
        headers: {
          Authorization: "Bearer " + token
        }
      });
      return response
    } catch (error) {
      console.error("Error fetching data", error);
      return error;
    }
}
export async function UnAuthGetRequest(endpoint) {
   
        const response = await axios.get(`https://api.orgtools.app${endpoint}`, {
          headers: {
            
          }
        });
        return response
      
}
