import { Authenticator, PhoneNumberField } from "@aws-amplify/ui-react";
import "./Labels.css";
export function LoggedInAuthPage(props){
    
    const pageComponent = props.element;
    return(
        <Authenticator
            hideSignUp={true}>
                {pageComponent}
            </Authenticator>
    )
}
export function LoginButtonOnly(){
    <Authenticator
            hideSignUp={true}>
            </Authenticator>
}
export function LoginRegisterPrompt(props){
    const pageComponent = props.element;

    return(
        <Authenticator
            signUpAttributes={["preferred_username","email","phone_number","birthdate","name","family_name","nickname"]}
        >
                {pageComponent}
            </Authenticator>
    )
}
