import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Dropdown } from 'react-bootstrap';
import { AuthGetRequest, AuthPostRequest } from '../APIGateWayRequestMiddleWare';

export function CreateNewOrganizationFlow(props){
    const { close } = props;
    const [groupData, setGroupData] = useState({ Group_Name: '', Group_Join_Code: '', Subscription_ID: null });
    const [isValidJoinCode, setIsValidJoinCode] = useState(true);
    const [subscriptions, setSubscriptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    // Function to fetch Subscription IDs from the API
    const fetchSubscriptions = async () => {
        try {
            const response = await AuthGetRequest('/v1/user/available-licenses');
            setSubscriptions(response.data);
            console.log(response.data);
            setIsLoading(false);
        } catch (error) {
            console.error('Failed to fetch subscriptions:', error);
            setIsLoading(false);
        }
    };
    const validateJoinCode = (code) => {
        const isLengthValid = code.length >= 8;
        const isCharValid = /^[a-zA-Z0-9-_]+$/.test(code);
        return isLengthValid && isCharValid;
    };
    useEffect(() => {
        fetchSubscriptions();
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setGroupData(prevState => ({
            ...prevState,
            [name]: value
        }));

        if (name === "Group_Join_Code") {
            setIsValidJoinCode(validateJoinCode(value));
        }
    };

    const handleSelectChange = (event) => {
        setGroupData(prevState => ({
            ...prevState,
            Subscription_ID: event.target.value
        }));
    };

    const handleSubmit = async () => {
        if(groupData.Group_Join_Code.length === 0){
            alert("Join code must not be blank");
            return;
        }
        if (!isValidJoinCode) {
            alert("Invalid join code. Please enter a valid code.");
            return;
        }
        if(groupData.Group_Name ===''){
            alert("You must enter a group name!")
            return;
        }
        setIsLoading(true);
        try {
            // Construct the path with the parameters
            const path = `/v1/organizations/create-root-organization?groupName=${groupData.Group_Name}&joinCode=${groupData.Group_Join_Code}&subscriptionID=${groupData.Subscription_ID}`;
    
            // Make sure to replace with the correct base URL if necessary
            const response = await AuthPostRequest(path, "{}")
            console.log(response);
            if(response.status === 201){
                window.location.href=`/admin/groups/${response.data}/configure-organization`;
                close();
            }
            else if (response.response.status !== 201){
                alert(response.response.data);
            }
            
            

        } catch (error) {
            console.error('Failed to create organization:', error);
            // Handle error as needed, possibly showing a message to the user
        } finally {
            setIsLoading(false);
        }
    };
    

    return(
        <Modal show={true} onHide={close}>
            <Modal.Header closeButton>
                <Modal.Title>Create New Organization</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="group_name">
                        <Form.Label>Organization Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Organization Name"
                            name="Group_Name"
                            onChange={handleInputChange}
                            value={groupData.Group_Name}
                        />
                    </Form.Group>
                    <Form.Group controlId="group_code">
                        <Form.Label>Organization Join Code</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Join Code"
                            name="Group_Join_Code"
                            onChange={handleInputChange}
                            value={groupData.Group_Join_Code}
                            isInvalid={!isValidJoinCode}
                        />
                        {!isValidJoinCode && (
                            <Form.Control.Feedback type="invalid">
                                Join code must be at least 8 characters long and contain only letters, numbers, '-', and '_'.
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group controlId="subscription_id">
                        <Form.Label>Subscription License</Form.Label>
                        <Form.Control
                            as="select"
                            onChange={handleSelectChange}
                            value={groupData.Subscription_ID || ''}
                        >
                            <option value="">Select Subscription</option>
                            {isLoading ? (
                                <option value="">Loading...</option>
                            ) : (
                                subscriptions.map((subscription, index) => (
                                    <option key={index} value={subscription.subscriptionId} disabled={subscription.isRedeemed}>
                                        {subscription.tierName} ({subscription.tierType}) {subscription.subscriptionEnd && (<>Expires {subscription.subscriptionEnd}</>)}
                                    </option>
                                ))
                            )}
                        </Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={close}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Create Organization
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
