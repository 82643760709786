import { CAccordion, CAccordionBody, CAccordionHeader, CAccordionItem } from "@coreui/react/dist";
import { useState, useEffect } from "react";
import { Button, Modal, NavLink, Spinner, Table } from "react-bootstrap";
import { useParams, useLocation, useNavigate } from "react-router-dom";

import { processImageUrl } from "./UserViews/UserCard";
import moment from 'moment-timezone';

import { Archive, ArchiveFill, ArrowCounterclockwise, Check, InfoCircle, X } from "react-bootstrap-icons";
import { AuthGetRequest } from "../APIGateWayRequestMiddleWare";
import RoleRow from "./RoleTable";
const OrgStructure = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { org, Action, permissions } = props;
    const [showModal, setModalDisp] = useState(false);
    const [activeUsers, setActiveUsers] = useState([{}, {}])
    const [proposedUsers, setProposedUsers] = useState([]);
    const [isLoadingApproved, setIsLoading] = useState(false);
    const [isLoadingProposed, setIsLoadingProposed] = useState(false);
    const [organizations, setOrganizations] = useState([]);

    const [roles, setRoles] = useState([]);
    useEffect(() => {
        if (showModal) {
            AuthGetRequest(`/v1/organizations/roles?orgId=${org.id}`)
                .then((response) => {
                    if (response.status === 200) {
                        console.log(response.data)
                        setRoles(response.data);
                    }
                })
            AuthGetRequest(`/v1/organizations/sub-orgs?orgId=${org.id}`)
                .then((response) => {
                    if (response.status === 200) {
                        console.log(response.data)
                        setOrganizations(response.data);
                    }
                })
        }
    }, [showModal])

    const toggleModal = () => {
        setModalDisp(!showModal);
    }
    const editOrg = () => {
        navigate(`/admin/groups/${org.id}/configure-organization`)
        window.location.reload();
    }
    const handleViewRoleModal = () => {

    }
    const deactivateUserRole = (e) => {
        alert("Deactivating " + e);
    }
    const declineProposed = (e) => {

    }
    const apporveProposed = (e) => {

    }
    const viewUserRoleData = (e) => {

    }
    const convertToLocalTime = (Time) => {
        if (!Time) { return 'Formatting Error'; }
        const localTime = moment.utc(Time, 'MMM D, YYYY, h:mm:ss A').tz(moment.tz.guess());
        return localTime.format('MMM D, YYYY, h:mm A z');
    }
    return (
        <>
            <Modal onHide={toggleModal} show={showModal}>
                <Modal.Header closeButton><Modal.Title>{org.groupName}</Modal.Title></Modal.Header>
                <Modal.Body>
                    <CAccordion>
                        <CAccordionItem itemKey={1}>
                            <CAccordionHeader>Structure Information</CAccordionHeader>
                            <CAccordionBody>
                                <div><strong>Created Date: </strong>{convertToLocalTime(org.creation_date)}</div>
                                <div><strong>Join Code: </strong>{org.groupJoinCode}</div>
                                <div><strong>Structure Type: </strong>{org.groupType}</div>
                                <div><strong>Auto Join: </strong>{org.auto_join ? (<>Enabled</>) : (<>Disabled</>)}</div>
                                <div style={{ display: 'block' }}>
                                    <Button variant="secondary" onClick={() => { editOrg() }}>Edit</Button>
                                </div>

                            </CAccordionBody>
                        </CAccordionItem>
                        <CAccordionItem itemKey={2}>
                            <CAccordionHeader>Newest Users ({activeUsers.length})</CAccordionHeader>
                            <CAccordionBody>
                                <div style={{ overflowX: 'auto', maxHeight: '400px' }}>
                                    <Table striped boardered hover>
                                        <thead>
                                            <tr>
                                                <th />
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoadingApproved &&
                                                (<><tr><td><><Spinner /></></td><td>Loading ...</td></tr></>)}
                                            {activeUsers.map(user => (
                                                <tr key={user.sub_id} onClick={() => { viewUserRoleData(user.roleId) }}>
                                                    <td><img src={processImageUrl(user.profile_image)} style={{ width: '50px', height: '50px' }} /></td>
                                                    <td>{user.first_name}</td>
                                                    <td>{user.last_name}</td>
                                                    <td>
                                                        <Button variant="info" onClick={() => { viewUserRoleData(user.roleId) }}><InfoCircle /></Button>

                                                        <Button variant="danger" onClick={() => { deactivateUserRole(user.roleId) }}><ArchiveFill /></Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </CAccordionBody>
                        </CAccordionItem>
                        <CAccordionItem itemKey={3}>
                            <CAccordionHeader>Needing Join Apporval ({proposedUsers.length})</CAccordionHeader>
                            <CAccordionBody>
                                <div style={{ overflowX: 'auto', maxHeight: '400px' }}>
                                    <Table striped boardered hover>
                                        <thead>
                                            <tr>
                                                <th />
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoadingProposed &&
                                                (<><tr><td><><Spinner /></></td><td>Loading ...</td></tr></>)}
                                            {activeUsers.map(user => (
                                                <tr key={user.sub_id} onClick={() => { viewUserRoleData(user.roleId) }}>
                                                    <td><img src={processImageUrl(user.profile_image)} style={{ width: '50px', height: '50px' }} /></td>
                                                    <td>{user.first_name}</td>
                                                    <td>{user.last_name}</td>
                                                    <td>
                                                        <Button variant="success" onClick={() => { apporveProposed(user.roleId) }}><Check /></Button>
                                                        <Button variant="info" onClick={() => { viewUserRoleData(user.roleId) }}><InfoCircle /></Button>
                                                        <Button variant="danger" onClick={() => { declineProposed(user.roleId) }}><X /></Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </CAccordionBody>
                        </CAccordionItem>
                        <CAccordionItem itemKey={4}>
                            <CAccordionHeader>Roles ({roles.length})</CAccordionHeader>
                            <CAccordionBody>
                                <Table striped responsive>
                                    <thead>
                                        <tr>
                                            <th>Role Name</th>
                                            <th>Role Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {roles.length === 0 ?
                                            (<>
                                                <tr>
                                                    <td></td>
                                                    <td>No roles found for this organization</td>
                                                </tr>
                                            </>) :
                                            (
                                                <>
                                                    {roles.map(role => (
                                                        <RoleRow role={role} orgId={org.id} permission={permissions} />
                                                    ))}
                                                </>
                                            )}
                                    </tbody>
                                </Table>
                            </CAccordionBody>
                        </CAccordionItem>
                        <CAccordionItem itemKey={5}>
                            <CAccordionHeader>Structure ({organizations.length})</CAccordionHeader>
                            <CAccordionBody>
                                <Table striped responsive>
                                    <thead>
                                        <tr>
                                            <th>Organization Name</th>
                                            <th>Date Created</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {organizations.length === 0 ?
                                            (<>
                                                <tr>
                                                    <td></td>
                                                    <td>No organization structures found</td>
                                                </tr>
                                            </>) :
                                            (
                                                <>
                                                    {organizations.map(org => (
                                                        <OrgStructure org={org} permission={permissions} />
                                                    ))}
                                                </>
                                            )}
                                    </tbody>
                                </Table>
                            </CAccordionBody>
                        </CAccordionItem>
                    </CAccordion>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
            <tr >
                <td onClick={toggleModal}>{org.groupName}</td>
                <td onClick={toggleModal}>{convertToLocalTime(org.creation_date)}</td>
                <td>{Action}</td>
            </tr>
        </>
    )
}
export default OrgStructure;