import './Subscription.css'
import { useState, useEffect } from "react"
import { RenderedProductSubscription } from "./StripeProductHolder"
import MySubscriptions from './My Subscriptions';
import { Spinner } from '../CustomComponents/Spinners/Spinner';
import { loadStripe } from '@stripe/stripe-js';
import { useSearchParams } from 'react-router-dom';
import { Alert, Button } from 'react-bootstrap';
import { AuthGetRequest } from '../APIGateWayRequestMiddleWare';

export function MySubscriptionContainer() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [successful, setSuccess] = useState(false);
    const [cancelledCheckout, setCancelCheckout] = useState(false);
    useEffect(() => {
        if(searchParams.get('subscribed') === 'false'){
            setCancelCheckout(true);
        }
        else if(searchParams.get('subscribed') === 'true'){
            setSuccess(true);
        }
        // else {
        //   You can include this block if needed, but it seems to reset states which might not be intended
        //   setSuccess(false);
        //   setCancelCheckout(false);
        // }
    }, [searchParams]);
    const stripePromise = loadStripe("pk_live_51My4b0IHkGHu4JAV1PXnBBDhBuKDw4J5PpieIMBewufuGuiIWLK2R0MFtnHCqBkU5hf1KUCgNcVJAQ1XBwOWJGzw00EkG9NE8h"); // Use your Stripe publishable key
    
    const [products, setPublicSubscriptions] = useState([])
    useEffect(() => {
        // The URL to fetch data from
        const apiUrl = 'https://api.orgtools.app/v1/subscriptions/public-license-list';

        fetch(apiUrl)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                // Assuming the API returns the exact JSON structure you provided
                if (data.success && data.productTierList) {
                    setPublicSubscriptions(data.productTierList);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);
    const [isLoadingPortal, setLoadingPortal] = useState(false);
    const getBillingPortal = ()=>{
        setLoadingPortal(true);
        AuthGetRequest('/v1/subscriptions/manage')
        .then((response)=>{
            if(response.data.url){
                window.location.href = response.data.url;
            }
        })
    }
    //This function will get all availible stripe plans, and get the user's subscriptions they have.
    return (
        <div>
            {successful && (<Alert variant='success'>License added to account, you may begin to use it!</Alert>)}
            {cancelledCheckout && (<Alert variant='danger'>Subscription checkout session cancelled.</Alert>)}

            <h1>Licenses:</h1>
            <h3>Purchase License</h3>
            <div className="products-container" style={{ width: '100%', height: '100%' }}>
                {products.length === 0 &&(<Spinner/>)}
                {products.map(product => (
                    <RenderedProductSubscription key={product.ProductDatabaseId} product={product} stripePromise = {stripePromise} />
                ))}
            </div>
            <br/>
            <Button style={{right:'0px', justifySelf:"right"}} variant='success' onClick={getBillingPortal} disabled={isLoadingPortal}>{isLoadingPortal && (<><Spinner/> Manage Billing</>)}{!isLoadingPortal && (<>Manage Billing</>)}</Button>

            <h3>My Licenses</h3>
            <MySubscriptions/>
        </div>
    );
}