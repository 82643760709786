import { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { AuthGetRequest } from "../APIGateWayRequestMiddleWare";
import { Alert } from "react-bootstrap";
import { Spinner } from "../CustomComponents/Spinners/Spinner";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ToolboxOverview from "./Config_Tabs/OverviewTab";
import AppointmentToolboxConfig from "./Config_Tabs/AppointmentsToolBoxConfig";
import RoleManagementToolbox from "./Config_Tabs/RoleManagement";
import SubOrganizaionManagementToolbox from "./Config_Tabs/SubOrgToolbox";

function OrgConfigureScreen() {
    const { orgID } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState("overview");

    const [loading, setLoading] = useState(false);
    const [permissions, setPermissions] = useState([])
    const [roles, setRoles] = useState([]);

    

    /** Logic for this screen
     * In order to display the appropriate configurations, we need to first call an endpoint that will get the list of
     * enabled subscription attributes, these include the list of enabled tools, and configuration of subgroups. So in this
     * screen you will be able to configure the landing page for appointments, create, update, view, and delete subgroups,
     * manage subscriptions that are linked to the selected organization, link more add-on subscriptions, customize the user
     * toolbox access, add providers, members. Essensially full on dashboard.
     */

    useEffect(() => {
        setLoading(true);
        AuthGetRequest(`/v1/organizations/leadership-toolbox?orgId=${orgID}`)
            .then((response) => {
                if (response.status === 200) {
                    setRoles(response.data.roles);
                    setPermissions(response.data.permissions)
                }
                else {

                }
                setLoading(false);

            })
    }, []);
    useEffect(() => {
        // Extract hash from URL and set as active tab
        const hash = location.hash.replace("#", "");
        if (hash) setActiveTab(hash);
    }, [orgID]);

    const handleSelect = (key) => {
        setActiveTab(key);
        navigate(`#${key}`, { replace: true });
    };


    if(loading){
        return(<>
        <h2>Organization Configuration:</h2>
        <Spinner/>
        </>)
    }
    if (!permissions.includes("Root_Admin") && !loading) {
        return (<>
            <h2>Organization Configuration:</h2>
            <Alert variant="danger">You do not have permission to access this page!</Alert>
        </>);
    }
    return (
        <div>
            <h2>Organization Configuration:</h2>
            <Tabs
                onSelect={handleSelect}
                activeKey={activeTab}
                id="Org-Config-Tabs"
                className="mb-3"
                justify
                fill
                variant="pills"
                style={{color:'white', backgroundColor:'#0a2a525d', boarder:'black'}}
                >
                    <Tab eventKey="overview" title="Overview" color="white">
                        <ToolboxOverview orgID={orgID} permissions={permissions}/>
                    </Tab>
                    <Tab eventKey='sub-organizations' title='Organization Structure'>
                        <SubOrganizaionManagementToolbox orgID={orgID} permissions={permissions}/>
                    </Tab>
                    <Tab eventKey="roles" title="Role Management">
                        <RoleManagementToolbox  orgID={orgID} permissions={permissions}/>
                    </Tab>
                    <Tab eventKey="appointments" title="Appointment Toolbox">
                        <AppointmentToolboxConfig orgID={orgID} permissions={permissions}/>
                    </Tab>
                    {/* <Tab eventKey="tasks" title="Tasks Toolbox">

                    </Tab>
                    <Tab eventKey="calendars" title="Calendar Toolbox">

                    </Tab>
                    <Tab eventKey="communications" title="Communications Toolbox">

                    </Tab> */}
                </Tabs>
        </div>
    )
}




export default OrgConfigureScreen;