import React from 'react';
import { Button } from 'react-bootstrap';
export default function WeekSelector({ dateRange, onPrevClick, onNextClick }) {
    return (
        <div className="week-selector">
            <Button  variant='secondary' onClick={onPrevClick}>&lt;&lt;</Button>
            <span>Week of {dateRange}</span>
            <Button variant='secondary' onClick={onNextClick}>&gt;&gt;</Button>
        </div>
    );
}
