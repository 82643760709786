import { Spinner } from "../CustomComponents/Spinners/Spinner";
import { useEffect, useState } from 'react';
import { Auth, Hub } from 'aws-amplify';

export function BlankLogin(){
  const[target, setTarget] = useState(window.localStorage.getItem('login-redirect-path') || '/');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser]=useState(null);
  useEffect(() => {
    // Check the initial authentication state when the component mounts
    checkUser();

    // Subscribe to authentication events
    const authSubscription = Hub.listen('auth', ({ payload }) => {
      if (payload.event === 'signIn') {
        // User has signed in
        checkUser();
      } else if (payload.event === 'signOut') {
        // User has signed out
        setUser(null);
      }
    });

    // Clean up the subscription when the component unmounts
    return () => {
      authSubscription();
    };
  }, []);


  async function checkUser() {
    try {
      await Auth.currentAuthenticatedUser();
      setIsLoggedIn(true); // User is authenticated
      console.log("Target: " + target)
      window.location.href=`${target}`;
    } catch (error) {
      setIsLoggedIn(false); // No user is signed in
    }
  }
    return(<div>
        <Spinner message={"Logging you in..."}/>
    </div>)
}