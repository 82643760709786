import React, { useState, useEffect } from 'react';
import './loaderContainer.css'
const Spinner = (props) => {
    const Action = props.action;
    const Messgae = props.message;
    return (
        <>
            <div className="popup">
                <div className="popup-inner">
                    <table className='loader'>
                        <tr>
                            <td>
                                <div className="spinner-holder">
                                    <div className="lds-spinner">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                {Action}
                            </td>
                        </tr>
                        <tr>
                            {Messgae}
                        </tr>
                    </table>
                </div>
            </div>
        </>






    )
}
export { Spinner }