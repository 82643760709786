import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Switch from 'react-bootstrap/Switch';
import axios from 'axios';

const RoleForm = ({ show, groupID, handleClose }) => {
  const [formFields, setFormFields] = useState({
    role_title: '',
    role_description:'',
    display_title: true,
    is_over_subgroup: false,
    sub_group_link_jurisdiction: '',
    is_belong_subgroup: false,
    sub_group_link_belong: '',
    is_leadership: false,
    requires_second_approval: false
  });

  const [subGroups, setSubGroups] = useState([]);
  const [titleError, setErrorTitle] = useState('');
  const [displayDescriptionMissing, setMissingDescription] = useState(false);
  const [displayMissingBelonging, setMissingBelonging] = useState(false);
  const [displayMissingJurisdiciton, setMissingJurisdiction]= useState(false);
  const [validInput, setValidInput] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
        const token = localStorage.getItem('token');
    //   const response = await axios.get(`/api/groups/${groupID}/settings/subgroups/list/all`,{
    //     headers: { Authorization: `Bearer ${token}` }
    //   });
    //  setSubGroups(response.data.list);
    };

    fetchData();
  }, [groupID]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = localStorage.getItem('token');
    const response = await axios.post(`/api/groups/${groupID}/settings/roles/create`, formFields,{
        headers: { Authorization: `Bearer ${token}` }
      });
    if(response.status === 201){
        console.log('Response', response.data);

    }
    handleClose(null);
  };

  const handleInputChange = (event) => {
    setFormFields({
      ...formFields,
      [event.target.name]: event.target.value,
    });
  };

  const handleSwitchChange = (field) => {
    setFormFields({
      ...formFields,
      [field]: !formFields[field],
    });
  };
  //Validate inputs
  useEffect(()=>{
    if(formFields.display_title === 'ROOT_ORG_ROLE'){
        setErrorTitle('This title reserved and cannot be used.');
        setValidInput(false);
    }
    else{
        setValidInput(true)
    }
    if(formFields.role_description === ''){
        setMissingDescription(true);
        setValidInput(false);
    }
    else{
        setMissingDescription(false);
        setValidInput(true);
    }
    if(formFields.is_over_subgroup && formFields.sub_group_link_jurisdiction === ''){
        setMissingJurisdiction(true);
        setValidInput(false);
    }
    else{
        setMissingJurisdiction(false);
        setValidInput(true);
    }
    if(formFields.is_belong_subgroup && formFields.sub_group_link_belong === ''){
        setMissingBelonging(true);
        setValidInput(false);
    }
    else{
        setMissingBelonging(false);
        setValidInput(true);
    }

  },[formFields.display_title])

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Create new role</Modal.Title>
      </Modal.Header>
      
        <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group controlId="role_title">
            <Form.Label>Role Name</Form.Label>
            <Form.Control type="text" name="role_title" required onChange={handleInputChange} />
          </Form.Group>
          <Form.Group controlId="role_title">
            <Form.Label>Role description</Form.Label>
            <Form.Control type="text" name="role_description" required onChange={handleInputChange} />
          </Form.Group>
          <Form.Group controlId="display_title">
            <Form.Label>Display title Label</Form.Label>
            <Form.Check type="switch" checked={formFields.display_title} name="display_title" onChange={() => handleSwitchChange('display_title')} />
          </Form.Group>
          <Form.Group controlId="is_over_subgroup">
            <Form.Label>Is role over subgroup?</Form.Label>
            <Form.Check type="switch" name="is_over_subgroup" checked={formFields.is_over_subgroup} onChange={() => handleSwitchChange('is_over_subgroup')} />
          </Form.Group>
          {formFields.is_over_subgroup && (
          <>
          <Form.Group controlId="sub_group_link_jurisdiction">
              <Form.Label>Select Subgroup</Form.Label>
              <Form.Select name="sub_group_link_jurisdiction" required onChange={handleInputChange}>
              <option value="">Select Subgroup</option>
                {subGroups.map((group) => (
                  <option key={group.id} value={group.id}>
                    {group.group_title}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="is_leadership">
            <Form.Label>Is role a leadership position?</Form.Label>
            <Form.Check type="switch" name="is_leadership"  onChange={() => handleSwitchChange('is_leadership')} />
          </Form.Group>
            
          </>
          )}
          <Form.Group controlId="is_belong_subgroup">
            <Form.Label>Is role apart of subgroup?</Form.Label>
            <Form.Check type="switch" name="is_belong_subgroup" onChange={() => handleSwitchChange('is_belong_subgroup')} />
          </Form.Group>
          {formFields.is_belong_subgroup && (
            <>
            <Form.Group controlId="sub_group_link_belong">
              <Form.Label>Select Subgroup</Form.Label>
              <Form.Select name="sub_group_link_belong" required onChange={handleInputChange}>
              <option value="">Select Subgroup</option>

                {subGroups.map((group) => (
                  <option key={group.id} value={group.id}>
                    {group.group_title}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            </>
            
          )}
          <Form.Group controlId="is_read_only">
            <Form.Label>Role requires two approvers?</Form.Label>
            <Form.Check type="switch" name="requires_second_approval"  onChange={() => handleSwitchChange('requires_second_approval')} />
          </Form.Group><br></br><br></br>
          
          </Modal.Body>
          <Modal.Footer>
          <Button variant="primary" type="submit" disabled={!validInput}>
            Create Role
          </Button>
          </Modal.Footer>
        </Form>
        
      
    </Modal>
  );
};

export default RoleForm;
