import React from 'react';
import AppointmentHolder from './SlotContainer';

export default function DaySlotHolder({ day, appointments }) {
    return (
        <div className="day-slot-holder" style={dayStyle}>
            <h4>{day}</h4>
            {appointments.map((appt, index) => (
                <AppointmentHolder key={index} {...appt} />
            ))}
        </div>
    );
}
const dayStyle = {
    
    border: '1px solid #e6e6e6',
    borderRadius: '8px',
    padding: '4px',
    boxSizing: 'border-box',
    background: '#0a2a5244',
    margin: '0 2px'
};