import { useEffect, useState } from "react";
import { FormControl, InputGroup, Row, Spinner, Table } from "react-bootstrap";
import { AuthGetRequest } from "../../APIGateWayRequestMiddleWare";
import { processImageUrl } from "./UserCard";

const SelectUser = (props) => {
    const { url, setSelectedUser, selectedUser } = props;
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState('')
    const [isLoading, setIsLoading] = useState(false);

    const handleSearch = (e) => {
        setSearch(e.target.value);
    }
    
    const filteredUsers = !search ? users : users.filter(user =>
        (user.first_name.toLowerCase().includes(search.toLowerCase()) ||
            user.last_name.toLowerCase().includes(search.toLowerCase()) ||
            user.alias.toLowerCase().includes(search.toLowerCase()))
        );

    useEffect(()=>{
        setIsLoading(true)
        AuthGetRequest(url).then((response)=>{
            if(response.status === 200){
                console.log(response.data)
                setUsers(response.data);

            }

        }).catch((error)=>{}).finally(()=>{
            setIsLoading(false);
        })
    },[])

    return(
        <>
        <h4>Search</h4>
            <InputGroup className="mb-3">
                <FormControl
                    placeholder="Search by name"
                    aria-label="Search by name"
                    onChange={handleSearch}
                    />
            </InputGroup>
            {!selectedUser && (
                <div style={{overflowX:'auto', maxHeight:'400px'}}>
                    <Table striped boardered hover>
                        <thead>
                            <tr>
                                <th/>
                                <th>First Name</th>
                                <th>Last Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading && 
                            (<><tr><td><><Spinner/></></td><td>Loading ...</td></tr></>)}
                            {filteredUsers.map(user=>(
                                <tr key={user.sub_id} onClick={() => setSelectedUser(user)}>
                                    <td><img src={processImageUrl(user.profile_image)} style={{width:'50px', height:'50px'}}/></td>
                                    <td>{user.first_name}</td>
                                    <td>{user.last_name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            )}
        </>
    )
}
export default SelectUser;