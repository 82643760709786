import React, { useState, useEffect } from 'react';
import { Table, Button, Badge, NavLink } from 'react-bootstrap';
import { AuthGetRequest } from '../APIGateWayRequestMiddleWare';
import Popup from '../CustomComponents/Popups/Popup';
import { CreateNewOrganizationFlow } from '../AdminScreens/CreateNewOrgFlows';

export function OrganizationManagementPortal() {
    const [groups, setGroups] = useState([]);
    const [licenses, setLicenses] = useState({ 'licenses_used': 0, 'licenses_total': 0 }); // default values can be adjusted
    const [isLoading, setIsLoading] = useState(true);
    const [dispNewOrg, setOrgDisplay] = useState(false);

    // Fetch the groups from the API
    useEffect(() => {
        fetch('your-api-endpoint-here/groups') // replace with your groups API
            .then((response) => response.json())
            .then((data) => {
                setGroups(data);
                console.log(data);
            })
            .catch((error) => {
                console.error('Error fetching data: ', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    // Fetch the license info from the API
    useEffect(() => {
        AuthGetRequest('/v1/user/license-usage') // replace with your licenses API
            .then((data) => {
                setLicenses(data.data);
            })
            .catch((error) => {
                console.error('Error fetching data: ', error);
            });
    }, []);

    const handleManage = (groupId) => {
        // Add logic to manage group
        console.log('Managing group:', groupId);
    };

    const handleArchive = (groupId) => {
        // Add logic to archive group
        console.log('Archiving group:', groupId);
    };
    const handleCreateOrganization = () => {
        // Logic for creating a new organization
        console.log('Creating new organization');
        setOrgDisplay(true);
    };
    const handleCloseCreateOrg = ()=>{
        setOrgDisplay(false);
    }

    const handleLicenseManagement = () => {
        // Logic for managing licenses
        console.log('Navigating to license management');
        window.location.href='/my-subscriptions'
        
    };


    return (
        <div>
            {dispNewOrg && (<CreateNewOrganizationFlow close={handleCloseCreateOrg}/>)}
            <h1>Manage my Organizations</h1>
            <div className="d-flex justify-content-between">
                <div>
                    License Count {licenses['licenses_used']} / {licenses['licenses_total']} Licenses Used
                </div>
                <Button disabled={licenses['licenses_used'] >= licenses['licenses_total']} onClick={handleCreateOrganization}>
                    Create Organization
                </Button>
            </div>
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Group Name</th>
                            <th>Date Created</th>
                            <th>Plan</th>
                            <th>Membership Count</th>
                            <th>Sub-Licenses remaining</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {groups.length === 0 ? (
                            <tr>
                                {licenses['licenses_used'] < licenses['licenses_total'] ? (
                                    <td colSpan="6" style={{ cursor: 'pointer' }} onClick={handleCreateOrganization}>
                                        + New Organization
                                    </td>
                                ) : (
                                    <td colSpan="6" style={{ cursor: 'pointer' }} onClick={handleLicenseManagement}>
                                        <NavLink className='navigation-link' to={'/my-subscriptions'}>Get a license to create a group.</NavLink>
                                    </td>
                                )}
                            </tr>
                        ) : (
                            groups.map((group, index) => (
                                <tr key={index}>
                                    <td>{group.name}</td>
                                    <td>{group.dateCreated}</td>
                                    <td>{group.plan}</td>
                                    <td>{group.membershipCount}</td>
                                    <td>{group.subLicensesRemaining}</td>
                                    <td>
                                        <Button variant="primary" onClick={() => handleManage(group.id)}>
                                            Manage
                                        </Button>{' '}
                                        <Button variant="secondary" onClick={() => handleArchive(group.id)}>
                                            Archive
                                        </Button>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </Table>
            )}
        </div>
    );
}
