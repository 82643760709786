import React from 'react';
import { FormSelect } from 'react-bootstrap';
import './Booking.css'
export default function FilterComponent({ appointmentTypes, providers, onTypeChange, onProviderChange }) {
    return (
        <div className="filter-component">
            <div>Appointment Type: </div>
            <div>
                <FormSelect
                id="select-type"
                defaultValue=""
                onChange={(e) => onTypeChange(e.target.value)}
            >
                <option disabled value="">Appointment Type</option>
                {appointmentTypes.map((type, index) => (
                    <option key={index} value={type}>{type}</option>
                ))}
                
            </FormSelect>
            </div>
            <div>Provider:</div>
            <div>
                <FormSelect
                id="select-provider"
                defaultValue=""
                onChange={(e) => {
                    const selectedProvider = providers.find(p => p.provider_Nickname === e.target.value);
                    onProviderChange(selectedProvider);
                }}
            >
                <option disabled value="">Provider</option>
                {providers.map((provider, index) => (
                    <option key={index} value={provider.provider_Nickname}>{provider.provider_Nickname}</option>
                ))}
            </FormSelect>
            </div>
            
            
            
        </div>
    );
}
