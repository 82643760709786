import { useEffect, useState } from "react";
import { Button, Spinner, Table } from "react-bootstrap";
import RoleRow from "../../CustomComponents/RoleTable";
import { ArrowClockwise, Trash } from "react-bootstrap-icons";
import RoleForm from "../../CustomComponents/UserViews/RoleCreationModal";
import { getOrgRoleList } from "../../SearchEngine";
import { AuthGetRequest } from "../../APIGateWayRequestMiddleWare";

const RoleManagementToolbox = (props) => {
    const { orgID, permissions } = props;
    const [displayModal, setAddRole] = useState(false);
    const [roles, setRoles] = useState([]);
    const [refreshCall, setRefresh] = useState(true);
    const [isLoading, setLoading] = useState(false);

    console.log(permissions)
    const toggleAddRoleModal = () => {
        setAddRole(!displayModal);
    }
    const RowAction = (role) => {
        const handleDeleteRole = () => {
            alert("delete " + role.id + "?")
        }
        return (
            <>
                <Button variant="danger" onClick={handleDeleteRole}><Trash /></Button>
            </>
        )
    }
    useEffect(() => {
        if (refreshCall) {
            setLoading(true)
            AuthGetRequest(`/v1/organizations/roles?orgId=${orgID}`)
                .then((response) => {
                    if (response.status === 200) {
                        console.log(response.data)
                        setRoles(response.data);
                        //response.data;
                    }
                }).finally(()=>{setLoading(false)})
        }
        setRefresh(false);

    }, [refreshCall])
    const refresh = () => {
        setRefresh(true);

    }

    return (
        <>
            <div>
                <h3>Roles:</h3>
                <Button variant="success" onClick={toggleAddRoleModal}>Create Role</Button>
                <RoleForm groupID={orgID} show={displayModal} handleClose={toggleAddRoleModal} />
                <Button variant="secondary" onClick={refresh}><ArrowClockwise /></Button>
            </div>
            <Table striped responsive>
                <thead>
                    <tr>
                        <th>Role Name</th>
                        <th>Role Description</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {isLoading ? (<><tr>
                        <td><Spinner/></td>
                        <td>Loading ...</td>
                        <td></td>
                        </tr></>):(<>{roles.length === 0 ?
                        (<>
                            <tr>
                                <td></td>
                                <td>No roles found for this organization</td>
                                <td><Button variant='success' disabled={!permissions.includes('Root_Admin') && !permissions.includes('Role_Manager')}>Add Role</Button></td>
                            </tr>
                        </>) :
                        (
                            <>
                                {roles.map(role => (
                                    <RoleRow role={role} Action={RowAction(role)} orgId={orgID} permission={permissions} />
                                ))}
                            </>
                        )}</>)}
                </tbody>
            </Table>
        </>
    )
}
export default RoleManagementToolbox;
