import { useState } from "react";
import { Alert, Button, Form, Modal, Spinner } from "react-bootstrap";
import { AuthGetRequest, AuthPostRequest } from "../../APIGateWayRequestMiddleWare";

export function JoinGroup({ show, onHide }) {
    const [joinCode, setJoinCode] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [isGroupValid, setGroupValid] = useState(false);
    const [group, setGroup] = useState({})
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoadingPreSignup, setPresignup] = useState(false);
    const [isRegistering, setRegistering] = useState(false);
    // Validator function
    const validateJoinCode = (code) => {
        const isLengthValid = code.length >= 8;
        const isCharValid = /^[a-zA-Z0-9-_]+$/.test(code);
        return isLengthValid && isCharValid;
    };

    const handleJoinCodeChange = (e) => {
        const code = e.target.value;
        setJoinCode(code);
        setIsValid(validateJoinCode(code));
    };
    const handleValidateCode = ()=>{
        setRegistering(false);
        setGroupValid(false);

        setPresignup(true);
        if (!isValid) {
            alert("Invalid join code. Please enter a valid code.");
            return;
        }
        setErrorMessage('');
        AuthGetRequest(`/v1/organizations/pre-join_info?join-code=${joinCode}`).then((response)=>{
            console.log(response);
            if(response.status === 200){
                setGroup(response.data)
                setGroupValid(true);
            }
            else{
                setGroup({});
                setErrorMessage(response.response.data);
            }
            
        }).finally(()=>{
            setPresignup(false)
        })
       
        // Additional logic to handle joining the group
    }

    const handleJoinGroup = () => {
        setErrorMessage('');
        setRegistering(true)
        if(group.orgId){
            // Additional logic to handle joining the group
            AuthPostRequest(`/v1/organizations/join-group?id=${group.orgId}`).then((response)=>{
                console.log(response);
                if(response.status === 200){
                    setRegistering(false);
                    //setGroupValid(false);
                    setJoinCode('');
                    console.log(response.data.orgId)
                    if(response.data.enrollmentStatus ==='Enrolled'){
                        window.location.href=`/groups/${response.data.orgId}/dashboard`;
                    }
                    else if(response.data.enrollmentStatus === 'Waiting approval'){
                        alert("Your enrollment has been requested. You'll be able to view the group once you're admitted.")
                        window.location.href=`/groups/${response.data.orgId}/waiting-approval`;
                    }
                }
                else{
                    setErrorMessage(response.response.data);
                }

            })
        }
        
        
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton><h2>Join a Group</h2></Modal.Header>
            <Modal.Body>
            {errorMessage && (<Alert variant="danger">{errorMessage}</Alert>)}

                <Form onSubmit={(e)=>{e.preventDefault()}}>
                    <Form.Group controlId="JoinCode">
                        <Form.Label>Organization Join Code</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Join Code"
                            name="Join_Code"
                            onChange={handleJoinCodeChange}
                            value={joinCode}
                            isInvalid={!isValid}
                        />
                        {!isValid && (
                            <Form.Control.Feedback type="invalid">
                                Join code must be at least 8 characters long and contain only letters, numbers, '-', and '_'.
                            </Form.Control.Feedback>
                        )}
                        
                    </Form.Group>
                    <br/>
                    <Button disabled={!isValid || isLoadingPreSignup} onClick={handleValidateCode}>{isLoadingPreSignup && (<><Spinner size="sm"/> Validating code...</>)}{!isLoadingPreSignup && (<>Validate Code</>)}</Button>
                </Form>
                {group.orgName && (<div>
                    <h4>Group Details</h4>
                    <div><strong>Group Name: </strong>{group.orgName}</div>
                    <div><strong>Group Type: </strong>{group.orgType}</div>
                    <div><strong>Group Leader: </strong>{group.leaderName}</div>
                    <div><strong>Members: </strong>{group.memberCount}</div>
                </div>)}
                
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleJoinGroup} disabled={!isGroupValid || isRegistering}>{isRegistering && (<><Spinner size="sm"/> Joining Group ...</>)} {!isRegistering && (<>Join Group</>)}</Button>
            </Modal.Footer>
        </Modal>
    );
}
