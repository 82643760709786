import React, { useEffect, useState } from 'react';
import { Spinner } from '../CustomComponents/Spinners/Spinner';
import { Container, Row, Col, Button, Image, Modal, Alert } from 'react-bootstrap';
import { AuthGetRequest, UnAuthGetRequest } from '../APIGateWayRequestMiddleWare';
import { Link, useParams } from 'react-router-dom'; // Import Link from react-router-dom
import { Card } from "react-bootstrap";
import { Authenticator } from '@aws-amplify/ui-react';
import { Auth, Hub } from 'aws-amplify';

import './Booking.css'



function LandingPage() {
  const [organization, setOrganization] = useState(null);
  const [providers, setProviders] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const { orgID } = useParams();

  useEffect(() => {
    if (orgID) {
      AuthGetRequest(`/v1/org-appts/landing-page?orgID=${orgID}`)
        .then(response => {
          // Assuming the response data structure contains { providers: [], appointments: [] }
          setOrganization(response.data.organization); // Assuming there is an organization object in the response
          setProviders(response.data.providers);
          setAppointments(response.data.appointmentTypes);
        })
        .catch(error => {
          console.error("There was an error fetching the data!", error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.error("No organization ID found!");
    }
  }, [orgID]);

  if (loading) {
    return <Spinner message={"Loading..."} />;
  }

  if (!organization) {
    // Assuming you have a NotFound component for such cases
    return <NotFound message={"Organization not found"} />;
  }

  return (
    <Container fluid>
      <Row className="mt-5 justify-content-center">
        <Col md={8} className="text-center">
          <h1>Welcome to {organization.orgName}'s Appointment Scheduler</h1>
          <p>Description: {organization.description}</p>
          {providers.length > 0 && (
            <div>
              <h2>Providers:</h2>
              <div className='products-container'>
                {providers.map(provider => (
                <ProviderCard providerInput={provider} />
              ))}
              </div>
              
            </div>
          )}
          
          {appointments.length > 0 && (
            <div>
              <h2>Available Appointments:</h2>
              {appointments.map(appointment => (
                <AppointmentAvailableType appointmentType={appointment} newApts={organization.acceptingAppointments} />
              ))}
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
}


function NotFound({ message }) {
  return (
    <div className="text-center mt-5">
      <h2>{message}</h2>
    </div>
  );
}

function ProviderCard({ providerInput }) {
  console.log(providerInput);
  return (
    <Card style={{ width: '32%', height:'auto' }}>
      <Card.Header><Card.Img src={providerInput.profileURL} roundedCircle style={{ width: '80%', borderRadius:'50%', border: '1px solid black' }} ></Card.Img></Card.Header>
      <Card.Title><strong>{providerInput.firstName} {providerInput.lastName}</strong></Card.Title>
      <Card.Subtitle><small>{providerInput.roles}</small></Card.Subtitle>
    </Card>
  )
}

function AppointmentAvailableType({ appointmentType, acceptingAppointments }) {
  const [userDetails, setUserDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    subgroup: '' // assuming subgroup is a dropdown
  });
  // Function to handle form field changes
  const handleChange = (e) => {
    setUserDetails({
      ...userDetails,
      [e.target.name]: e.target.value
    });
  };
  const [showBookingFlowModal, setModalDisplay] = useState(false);
  const [apptRequiresMembership, setMembershipRequired] = useState(true);
  const [continueAsGuest, setGuestMode] = useState(false);
  const [apptRequiresUserAccount, setUserAcctReqired] = useState(false);
  const [orgPubicRegistration, setOrgPublicRegistration] = useState(false);
  const [userIsMember, setUserMembershipEnrolled] = useState(true);


  //Authentication listenting:
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  useEffect(() => {
    // Check the initial authentication state when the component mounts
    checkUser();

    // Subscribe to authentication events
    const authSubscription = Hub.listen('auth', ({ payload }) => {
      if (payload.event === 'signIn') {
        // User has signed in
        checkUser();
      } else if (payload.event === 'signOut') {
        // User has signed out
        setUser(null);
      }
    });

    // Clean up the subscription when the component unmounts
    return () => {
      authSubscription();
    };
  }, []);
  async function checkUser() {
    try {
      await Auth.currentAuthenticatedUser();
      setIsLoggedIn(true); // User is authenticated
    } catch (error) {
      setIsLoggedIn(false); // No user is signed in
    }
  }
  const toggleModal = () => {
    setModalDisplay(!showBookingFlowModal);
  }
  console.log(appointmentType)
  const handleBookNextAvailable = (appointmentID) => {
    toggleModal();
    //Logic to trigger a modal:
    //Check to see if the appointment requires a login
    //If they are logged in, check to see if the appointment requeires them to be a member.
    //If they are NOT a member, then check to see if the organization allows public registration.

    //Try another approach logic:
    //If the organization requires them to be a member, then they must be logged in.
    //If they are not logged in then prompt the login.
    //If the user is not a member, check to see if the organization allows public registration
    //If it does, then add the sub-organization select drop down.
    //Display other defaults of the modal to book
    //If the organization doesn't allow public registration, then propmt the user to log in with an account that has membership

    //If the organization doesn't require membership, ask the to log in or continue as guest.
    //If they continue as guest, add the Basic information to create a user to the modal, then add a book now button.
  }
  const selectBooking = (selectedAppointment) => {

  }
  return (
    <Card style={{marginBottom: '3%'}}>
      <Card.Header>
        <Card.Title>{appointmentType.name}</Card.Title>
        <Card.Subtitle>{appointmentType.description}</Card.Subtitle>
      </Card.Header>
      <Card.Body>
        <Card.Text>Click below to schedule an appointment!</Card.Text>
        <Button onClick={() => handleBookNextAvailable(appointmentType.id)} disabled={!acceptingAppointments}>{acceptingAppointments && (<>Book Next Available Slot</>)}{!acceptingAppointments && (<>Not accepting new appointments</>)}</Button>
      </Card.Body>

      <Modal show={showBookingFlowModal} onHide={toggleModal} style={{maxHeight: '95vh'}}>
        <Modal.Header closeButton>
          <Modal.Title>{appointmentType.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {apptRequiresMembership && (
            <Authenticator
              hideSignUp={true}>
              {/**User is logged in */}
              {isLoggedIn && userIsMember && (
                <AppointmentPicker appointmentType={appointmentType} selectBooking={selectBooking} />
              )}
              {/**Register a listener to see if the user is a member of organization
                 * If they are display the booking component and a dropdown of the user's memberships within the allowed organizations
                 * if they are not then check if the organization allows public registration.
                 * If public registration is allowed, load all possible organizations the user can register for
                 * Else if public registration is not allowed, then display an error message.
                 * 
                 * This component must render if the user needs to have an account.
                 */}
              {/**This component will render if an account is not required
                  * If the user is not logged in, prompt the Login or continue as guest
                  * Once the user has chosen one or the other, display accordingly.
                  * If the user is logged in, then check to see if they are a member
                  * If they are not, get the list of suborganizations to register for.
                  * If the user is not logged in, then add form for contact info above the booking selections.
                  */}
            </Authenticator>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  )
}
function AppointmentPicker({ appointmentType, selectBooking }) {
  const [currentDate, setCurrentDate] = useState(getCurrentDate());
  const [nextOpenDate, setNextDate] = useState(currentDate);
  const [slots, setAppointmentSlots] = useState([]);
  const [nextIsCurrent, setNextIsCurrent] = useState(false);

  function getDeviceTimeZone() {
    const now = new Date();

    // Get the timezone offset in minutes and convert it to hours
    const timezoneOffsetInHours = -now.getTimezoneOffset() / 60;

    console.log(timezoneOffsetInHours); // Output could be something like -4 if the offset is UTC-4

    // For sending to the server, you may want to convert it to an ISO format (e.g., +HH:MM or -HH:MM)
    const offsetSign = timezoneOffsetInHours >= 0 ? '+' : '-';
    const offsetHours = Math.floor(Math.abs(timezoneOffsetInHours));
    const offsetMinutes = Math.abs(now.getTimezoneOffset() % 60);
    const timezoneOffsetISO = `${offsetSign}${offsetHours.toString().padStart(2, '0')}:${offsetMinutes.toString().padStart(2, '0')}`;
    console.log(timezoneOffsetISO)
    return timezoneOffsetISO;
  }

  useEffect(() => {
    UnAuthGetRequest(`/v1/org-appts/query-slots?appt-type-id=${appointmentType.id}&selected-date=${currentDate}&time-zone=${getDeviceTimeZone()}`)
      .then((response) => {
        setAppointmentSlots(response.data.slots);
        if(response.data.nextAvailableDate){
          const next = new Date(response.data.nextAvailableDate);

        setNextDate(next);
        }
        setNextDate(null);
      })
      .catch((err) => {
        console.log(err);
      })
      

  }, [currentDate]);
  useEffect(()=>{
    if(nextOpenDate === currentDate){
      setNextIsCurrent(true)
    }else{
      setNextIsCurrent(false)

    }
  },[currentDate]);
  
  const getNextSlotsDate = ()=>{
    setCurrentDate(nextOpenDate);
  }


  function getCurrentDate() {
    const date = new Date();
    return date;
  }

  return (
    <div style={{overflowY:'scroll', height:'70vh', position:'relative'}}>
      <h3>Availability for:{currentDate.toLocaleDateString()}</h3>
      <div className='Appointment-Slot-Containter'  >
        {slots.length > 0 && (
          <div>
            {slots.map(slot => (
              <Card style={{ width: '100%', marginBottom:'5%'}}>
                <Card.Header>{slot.appointmentTypeName} <small><small><small>({currentDate.toLocaleDateString()})</small></small></small></Card.Header>
                <Card.Body>
                  <strong>Appointment Details</strong>
                  <Card.Text>
                    <div><strong>Location: </strong> {slot.locationName}</div>
                    <div><strong>Time: </strong> {slot.startTime} - {slot.endTime}</div>
                    <div><strong>Provider:</strong> {slot.providerName}</div>
                  </Card.Text>

                </Card.Body>

                <Card.Footer>
                  <Button variant="primary" type="submit" onClick={selectBooking}>
                    Book Appointment
                  </Button>
                </Card.Footer>
              </Card>
            ))}
          </div>
        )}
        {slots.length === 0 && (
          <Alert variant='danger'>No appointments available for {currentDate.toDateString()}</Alert>
        )}
      </div>
      {nextOpenDate && (<Button variant='secondary' onClick={getNextSlotsDate}>Next Date: {nextOpenDate.toLocaleDateString()}</Button>)}
    </div>
  )
}

export default LandingPage;
